import { Container, EntityType, FilterField, Page } from "@amzn/ask-legal-domain";
import { Box, Link, PropertyFilterProps, SpaceBetween, TableProps } from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import * as React from "react";
import { DateTimeFormatter } from "../../components/common/DateTimeFormatter";
import { IdentityLink } from "../../components/common/IdentityLink";
import { PageLibraryLink } from "../../components/pageLibrary/PageLibraryLink";

export namespace DescriptionManagerPolarisFactory {
    export namespace Table {

        export const Title = Builder<TableProps.ColumnDefinition<Page.Data | Container.Data>>()
            .id("title")
            .header("Title")
            .cell((item: Page.Data | Container.Data) => {
                if (item.type === EntityType.Page) {
                    item = item as Page.Data;
                    return (<SpaceBetween size="s" direction="horizontal">
                        <Link variant="secondary" href={`#/page/${item.id}/admin`}>
                            {item.title}
                        </Link>
                    </SpaceBetween>);
                } else {
                    item = item as Container.Data;
                    return (<SpaceBetween size="s" direction="horizontal">
                        <Link variant="secondary" href={`#/page/${item.pageId}/live?container=${item.id}`}>
                            {item.title}
                        </Link>
                    </SpaceBetween>);
                }
            })
            .build();
        export const PageLibraryRef = Builder<TableProps.ColumnDefinition<Page.Data>>()
            .id("pageLibraryRef")
            .header("Page Library")
            .cell((item: Page.Data) => {
                if (item.pageLibraryId) {
                    return (<PageLibraryLink id={item.pageLibraryId} />);
                }
                else {
                    return (<>-</>);
                }
            }
            )
            .build();
        export const PageOwner = Builder<TableProps.ColumnDefinition<Page.Data>>()
            .id("pageOwner")
            .header("Page Owner")
            .cell((item: Page.Data) => {
                if (!!item.pageOwner) {
                    return <IdentityLink id={item.pageOwner.id} name={item.pageOwner.name} />;
                } else {
                    return <Box variant="small" textAlign="center" margin={{ left: "xxxl" }}>-</Box>;
                }
            })
            .build();
        export const Description = Builder<TableProps.ColumnDefinition<Page.Data>>()
            .id("description")
            .header("Description")
            .cell((item: Page.Data) => {
                if (!!item.description) {
                    return <Box variant="p" textAlign="left">{item.description}</Box>;
                }
            })
            .build();

        export const LastModified = Builder<TableProps.ColumnDefinition<Page.Data>>()
            .id("lastModified")
            .header("Last Modified Date")
            .cell((item: Page.Data) => {
                return (DateTimeFormatter.formatDate(item.lastModifiedDate));
            })
            .build();

        export function toColumnDefinitions() {
            return [
                Title,
                PageLibraryRef,
                Description,
                PageOwner,
                LastModified
            ];
        }

        export const filterByPageLibrary = Builder<PropertyFilterProps.FilteringProperty>()
            .key(FilterField.PageLibraryId)
            .operators(["="])
            .propertyLabel("Page Library Id")
            .build();

        export const filterByPage = Builder<PropertyFilterProps.FilteringProperty>()
            .key(FilterField.Name)
            .operators(["="])
            .propertyLabel("Page Name/Title")
            .build();
        export function toFilterOptions() {
            return [filterByPageLibrary, filterByPage];
        }
    }
}