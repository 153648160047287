import * as React from "react";
import { KendraConst, CustomListContent, CustomListObject } from "@amzn/ask-legal-domain";
import { ExpandableSection, SpaceBetween, Link, Box, Badge, Button, Icon } from "@amzn/awsui-components-react";
import { CustomListContentView } from "../../container/view-content/CustomListContentView";
import { SmartRichTextView } from "../../common/RichTextEditor/SmartRichTextView";
import "../../../styles/component/layout/SearchResultPageLayout";
import { QueryResultItem }  from "@aws-sdk/client-kendra";
import { getDocumentUri } from "../../common/common-utils";

export const FAQAnswers = (props: {
    instanceId: string;
    result: QueryResultItem[];
}) => {

    const DEFAULT_VIEW_SIZE = 3;

    const [viewableResults, setViewableResults] = React.useState<number>(DEFAULT_VIEW_SIZE);
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
        if (!expanded) {
            setViewableResults(DEFAULT_VIEW_SIZE);
        } else if (props.result.length > DEFAULT_VIEW_SIZE && expanded) {
            setViewableResults(props.result.length);
        }
    }, [props.result, expanded]);

    return (
        <React.Fragment>
            <div>
                {props.result.length === 1 && <h5>Related Question</h5>}
                {props.result.length > 1 && <h5>Related Questions</h5>}
                {props.result.slice(0, viewableResults).map(r => (
                    <div>
                        <ExpandableSection
                            defaultExpanded={false}
                            header={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <SmartRichTextView
                                        content={r.AdditionalAttributes[0].Value.TextWithHighlightsValue.Text}
                                    />
                                    <Link
                                        external
                                        externalIconAriaLabel="Opens in a new tab"
                                        href={getDocumentUri({ resultItem: r, instanceId: props.instanceId })}
                                    >
                                        {r.DocumentAttributes.find(a => a.Key === KendraConst.Attributes.Custom.ContentType)?.Value.StringValue === "Link" &&
                                            r.DocumentAttributes.find(a => a.Key === KendraConst.Attributes.Custom.PageTitle)?.Value.StringValue}
                                        {r.DocumentAttributes.find(a => a.Key === KendraConst.Attributes.Custom.ContentType)?.Value.StringValue !== "Link" &&
                                            r.DocumentAttributes.find(a => a.Key === KendraConst.Attributes.Custom.ContainerTitle)?.Value.StringValue}
                                    </Link>
                                    {r.DocumentAttributes.find(a => a.Key === KendraConst.Attributes.Custom.LegalOnly)?.Value.StringValue === "true" &&
                                        <Box variant="small" color="text-body-secondary" margin={{right: "m"}} padding="n">
                                            <Badge color="green">Legal Only</Badge>
                                        </Box>}
                                </SpaceBetween>
                            }>
                            {r.DocumentAttributes.find(a => a.Key === KendraConst.Attributes.Custom.ContentType)?.Value.StringValue === "Link" &&
                                <CustomListContentView
                                    content={CustomListContent.create([
                                        CustomListObject.create(r.AdditionalAttributes[0].Value.TextWithHighlightsValue.Text, r.AdditionalAttributes[1].Value.TextWithHighlightsValue.Text)
                                    ])}
                                />}
                            {r.DocumentAttributes.find(a => a.Key === KendraConst.Attributes.Custom.ContentType)?.Value.StringValue !== "Link" &&
                                <SmartRichTextView
                                    content={r.AdditionalAttributes[1].Value.TextWithHighlightsValue.Text}
                                />}
                        </ExpandableSection>
                    </div>
                ))}
                {props.result.length > DEFAULT_VIEW_SIZE &&
                    <div className="search-faq-div">
                        <hr className="search-faq-div-hr" />
                        <Button variant="link" onClick={() => { setExpanded(!expanded); }}>
                            {!expanded &&
                                <SpaceBetween size="xs" direction="horizontal">
                                    Show More
                                    <Icon name="caret-down-filled" />
                                </SpaceBetween>}
                            {expanded &&
                                <SpaceBetween size="xs" direction="horizontal">
                                    Show Less
                                    <Icon name="caret-up-filled" />
                                </SpaceBetween>}
                        </Button>
                        <hr className="search-faq-div-hr" />
                    </div>
                }
            </div>
        </React.Fragment>
    );
};