import { useAPI } from "../../hooks/api-hook";
import * as React from "react";
import { AppContext } from "../../setup/context";
import { useHistory } from "react-router-dom";
import {
    Box,
    Button,
    Modal,
    SpaceBetween,
    Spinner,
    TextContent,
    Tiles,
    TilesProps
} from "@amzn/awsui-components-react";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { Builder } from "builder-pattern";

export const PreferenceSelectionModal = (props: {
    onDismiss?: () => void;
}) => {
    const context = React.useContext(AppContext);
    const history = useHistory();

    const instanceLoadAllApiRunner = useAPI(
        context.getInstanceAPI().loadAll
    );

    React.useEffect(() => {
        instanceLoadAllApiRunner.submitRun({});
    }, []);

    const onClick = () => {
        if (!!selected) {
            context.setPreference(selected);
            history.push(`/instance/${selected}/home`);
            if (!!props.onDismiss) {
                props.onDismiss();
            }
        }
    };

    const [selected, setSelected] = React.useState<string>("");

    return (
        <Modal header="Choose Legal Team" onDismiss={() => {
            if (!!props.onDismiss) {
                props.onDismiss();
            }
        }} visible={true} footer={
            <Box float="right">
                <Button disabled={!selected} onClick={onClick} variant="primary">Go</Button>
            </Box>
          }>
            {instanceLoadAllApiRunner.status === "Running" && <Spinner />}
            {instanceLoadAllApiRunner.status === "Error" && <ErrorFlashbar error={instanceLoadAllApiRunner.data.err} />}
            {instanceLoadAllApiRunner.status === "Succeeded" &&
                <React.Fragment>
                    <SpaceBetween direction="vertical" size="l">
                        Select the PathFinder site you are attempting to visit.  PathFinder will remember your selection on this device, but you can always change your default PathFinder site by clicking on the name of the Legal team on the top right of the homepage.
                        {instanceLoadAllApiRunner.data.output.length === 0 &&
                            <TextContent >
                                <p style={{ textAlign: "center" }}>
                                    No instances found.
                                    <small>
                                        No instances to view.
                                    </small>
                                </p>
                            </TextContent>}
                        {instanceLoadAllApiRunner.data.output.length > 0 &&
                            <Tiles
                                value={selected}
                                onChange={({ detail }) => { setSelected(detail.value); }}
                                columns={3}
                                items={instanceLoadAllApiRunner.data.output
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map(v => {
                                        return Builder<TilesProps.TilesDefinition>()
                                            .label(v.name)
                                            .description(v.description)
                                            .value(v.id)
                                            .build();
                                    })}
                            />
                        }
                    </SpaceBetween>
                </React.Fragment>
            }
        </Modal>
    );
};