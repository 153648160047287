import {
    Container,
    ContainerRef,
    EntityFactory,
    EntityRef,
    ErrorCode,
    IPageDraft,
    Page,
    PageDraft
} from "@amzn/ask-legal-domain";
import { Box, Button, Flashbar, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { PageDraftModel } from "../../model/page-draft-model";
import { UIModel } from "../../model/ui-model";
import { AppContext } from "../../setup/context";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { CustomCommonContainerModal } from "../container/CommonContainerModal";
import { PageSection } from "../page-section/PageSectionComp";
import { PageBaseLayout } from "../page/PageBaseLayout";
import { PageDraftApprovalFlowNew } from "./PageDraftApprovalFlowNew";
import { PageDraftGeneralInfo } from "./PageDraftGeneralInfo";
import { EditContainerContent } from "../container/modal-content/EditContainerContent";
import { StringUtils } from "../common/common-utils";

export namespace PageDraftDetail {
    export const Preload = (props: {
        pageRef: EntityRef;
        setIsEditing: any;
        pageData?: Page.Data;
    }) => {
        const context = React.useContext(AppContext);
        const loadPageDraftRunner = useAPI(
            context.getPageDraftAPI().load
        );
        const createPageDraftRunner = useAPI(
            context.getPageDraftAPI().create
        );

        const [liveContainerMap, setLiveContainerMap] = React.useState(new Map<string, ContainerRef>());

        const createDraft = () => {
            createPageDraftRunner.submitRun(
                IPageDraft.CreatePageDraftInput.create(
                    props.pageRef
                )
            );
        };

        const draftDataState = UIModel.State.use<PageDraft.Data>({});

        const empty = (
            <Box textAlign="center" color="inherit">
                <b>No draft found</b>
                <Box padding={{ bottom: "s" }} variant="p" color="text-body-secondary">
                    Create page draft
                </Box>
                <Button
                    variant="primary"
                    loading={createPageDraftRunner.status === "Running"}
                    onClick={createDraft}>Create Draft
                </Button>
            </Box>);

        React.useEffect(() => {
            loadPageDraftRunner.submitRun(
                PageDraft.Factory.toPageDraftId(props.pageRef)
            );
            if (!props.pageData) return;
            props.pageData.sections.forEach(section => {
                section.containers.forEach(container => {
                    if (!liveContainerMap.has(container.id)) liveContainerMap.set(container.id, container);
                });
            });
            setLiveContainerMap(liveContainerMap);
        }, [props.pageRef.id, props.pageRef.version]);

        React.useEffect(() => {
            if (loadPageDraftRunner.status === "Succeeded") {
                draftDataState.setValue(loadPageDraftRunner.data.output);
            }
        }, [loadPageDraftRunner.status]);

        React.useEffect(() => {
            if (createPageDraftRunner.status === "Succeeded") {
                draftDataState.setValue(createPageDraftRunner.data.output);
                loadPageDraftRunner.reload();
            }
        }, [createPageDraftRunner.status]);

        return (
            <React.Fragment>
                {(loadPageDraftRunner.status === "Running" || loadPageDraftRunner.status === "New") && <Spinner />}
                {loadPageDraftRunner.status === "Error" && (loadPageDraftRunner.data.err.code === (404 as ErrorCode)) && empty}
                {loadPageDraftRunner.status === "Error" && !(loadPageDraftRunner.data.err.code === (404 as ErrorCode)) && (
                    <ErrorFlashbar
                        error={loadPageDraftRunner.data.err}
                        action={loadPageDraftRunner.data.err.code === (404 as ErrorCode) ?
                            <Button
                                variant="primary"
                                loading={createPageDraftRunner.status === "Running"}
                                onClick={createDraft}>Create Draft
                            </Button> : null
                        }
                    />)
                }
                {loadPageDraftRunner.status === "Succeeded" && !!draftDataState.value  &&
                    <Comp
                        state={draftDataState}
                        liveContainerMap={liveContainerMap}
                        setIsEditing={props.setIsEditing}
                    />
                }
            </React.Fragment>
        );
    };

    export const Comp = (props: {
        state: UIModel.State<PageDraft.Data>;
        setIsEditing: any;
        liveContainerMap?: Map<string, ContainerRef>;
    }) => {
        const context = React.useContext(AppContext);
        const pageBottomRef = React.useRef(null);
        const reorderContainerState = PageDraftModel.ReorderContainerState.use({
            draft: props.state.value
        });
        const addContainerState = PageDraftModel.EditContainerState.use({
            draftRef: EntityFactory.toEntityRef(props.state.value)
        });

        const reorderContainerRunner = useAPI(
            context.getPageDraftAPI().updateOrder
        );
        const addContainerRunner = useAPI(
            context.getPageDraftAPI().addContainer
        );
        const editingContainerIdState = UIModel.State.use<string>({
            initialValue: ""
        });

        const reorder = () => {
            reorderContainerRunner.submitRun(
                PageDraftModel.ReorderContainerState.toInput(
                    reorderContainerState
                )
            );
        };
        const addContainer = () => {
            addContainerRunner.submitRun(
                PageDraftModel.EditContainerState.toAddContainerInput(
                    addContainerState
                )
            );
            navigator.clipboard.writeText("");
        };

        const scrollToBottomOfPage = () => {
            if (
                addContainerState.locationField.value === "Main" &&
                addContainerRunner.status === "Succeeded"
            ) {
                pageBottomRef.current.scrollIntoView({ behavior: "smooth" });
                addContainerRunner.resetStatus();
            }
        };

        const [customSize, setCustomSize] = React.useState<"sm" | "lg" | "xl" | "xxl">("lg");

        React.useEffect(() => {
            if (reorderContainerRunner.status === "Succeeded") {
                reorderContainerState.setDraft(reorderContainerRunner.data.output);
            }
        }, [reorderContainerRunner.status]);

        React.useEffect(() => {
            if (addContainerRunner.status === "Succeeded") {
                addContainerState.activeContainerTypeField.setValue(null);
                reorderContainerState.setDraft(addContainerRunner.data.output);
            }
        }, [addContainerRunner.status]);

        React.useEffect(() => {
            props.state.setValue(reorderContainerState.draftField.value);
        }, [
            reorderContainerState.draftField.value.id,
            reorderContainerState.draftField.value.version
        ]);

        React.useEffect(() => {
            switch (addContainerState.activeContainerTypeField.value) {
                default: setCustomSize("xxl");
            }
        }, [addContainerState.activeContainerTypeField.value]);

        React.useEffect(scrollToBottomOfPage, [reorderContainerState.newOrderedSectionsField.value]);

        React.useEffect(() => {
            props.setIsEditing(
                !StringUtils.isEmpty(editingContainerIdState.value) ||
                !!addContainerState.activeContainerTypeField.value
            );
        }, [editingContainerIdState.value, addContainerState.activeContainerTypeField.value]);

        return  (
            <React.Fragment>
                { !!addContainerState.activeContainerTypeField.value && (
                    <CustomCommonContainerModal
                        header={`Add new ${
                            addContainerState.activeContainerTypeField.value.toLowerCase().split("_").join(" ")
                        } container`}
                        loading={addContainerRunner.status === "Running"}
                        child={
                            <EditContainerContent state={addContainerState} />
                        }
                        onCancel={() => {
                            addContainerState.activeContainerTypeField.setValue(null);
                            navigator.clipboard.writeText("");
                        }}
                        onSave={addContainer}
                        size={customSize}
                        disabled={!addContainerState.validState}
                    />
                )}
                <SpaceBetween direction="vertical" size="s">
                    <PageDraftApprovalFlowNew
                        state={props.state}
                        isEditing={
                            !StringUtils.isEmpty(editingContainerIdState.value) ||
                            !!addContainerState.activeContainerTypeField.value
                        }
                    />
                    <PageDraftGeneralInfo state={props.state}/>
                    { reorderContainerState.isDirty() && <Flashbar items={[{
                        type: "warning",
                        content: "You just changed the order of containers, click Save button to commit the change",
                        dismissible: false,
                        action: (
                            <SpaceBetween direction="horizontal" size="s">
                                <Button
                                    loading={reorderContainerRunner.status === "Running"}
                                    onClick={() => reorderContainerState.reset()}>Reset</Button>
                                <Button
                                    loading={reorderContainerRunner.status === "Running"}
                                    variant="primary" onClick={reorder}>Save</Button>
                            </SpaceBetween>
                        )
                    }]} />}
                    {props.state.value.status === "DRAFT" ? (
                        <PageBaseLayout
                            mainContent={
                                <PageSection.Edit
                                    location="Main"
                                    activeEditContainerState={editingContainerIdState}
                                    updateOrderState={reorderContainerState}
                                    addContainerState={addContainerState}
                                    pageBottomRef={pageBottomRef}
                                    liveContainerMap={props.liveContainerMap}
                                />
                            }
                            rightContent={
                                <PageSection.Edit
                                    location="Right"
                                    activeEditContainerState={editingContainerIdState}
                                    updateOrderState={reorderContainerState}
                                    addContainerState={addContainerState}
                                    liveContainerMap={props.liveContainerMap}
                                />
                            }
                        />) : (
                            <PageBaseLayout
                                mainContent={
                                    <PageSection.View
                                        section={props.state.value.newSections.find(s => s.location === "Main")}
                                        draftView={true}
                                        liveContainerMap={props.liveContainerMap}
                                    />
                                }
                                rightContent={
                                    <PageSection.View
                                        section={props.state.value.newSections.find(s => s.location === "Right")}
                                        liveContainerMap={props.liveContainerMap}
                                    />
                                }
                            />
                        )
                    }
                </SpaceBetween>
            </React.Fragment>
        );
    };
}