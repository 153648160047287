import { TableProps } from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import "./../../styles/component/legal-contact/view-layout.scss";
import { AddFieldDefinitionInput } from "@amzn/altar-sds-client";

export namespace AdvancedListPolarisFactory {
    export namespace Table {
        export const DisplayName = Builder<TableProps.ColumnDefinition<AddFieldDefinitionInput>>()
            .id("displayName")
            .header("Display Name")
            .cell(e => e.displayName)
            .minWidth("300px")
            .isRowHeader(true)
            .build();

        export const DataType = Builder<TableProps.ColumnDefinition<AddFieldDefinitionInput>>()
            .id("dataType")
            .header("Data Type")
            .cell(e => e.dataType)
            .minWidth("300px")
            .maxWidth("300px")
            .build();
    }
}