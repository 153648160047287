import { Select, SelectProps } from "@amzn/awsui-components-react";
import * as React from "react";

export const DropdownAnswers = (props: {
    containerId: string;
    answers: {
        nodeId: string;
        answer: string;
    }[];
    onAnswerSelected: (nodeId: string) => void;
    disabled: boolean;
}) => {
    const [selectedOption, setSelectedOption] = React.useState<SelectProps.Option>();

    const getOptions = (): SelectProps.Options => {
        return props.answers.map(x => ({
            label: x.answer,
            value: x.nodeId,
        }));
    };

    const onChangeHandler = (e: any) => {
        setSelectedOption(e.detail.selectedOption);
        props.onAnswerSelected(e.detail.selectedOption.value);
    };

    const selectProps: SelectProps = {
        selectedOption: selectedOption,
        options: getOptions(),
        filteringType: "auto",
        loadingText: "Loading options",
        placeholder: "Choose an option to continue",
        onChange: onChangeHandler,
        noMatch: "No match found",
        empty: "No options to display",
        disabled: props.disabled
    };

    return <Select {...selectProps} />;
};