import { AcceptedPreviewExtension, Document, DocumentFactory, EntityType, IFile, S3File } from "@amzn/ask-legal-domain";
import { Grid, StatusIndicator } from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import { DocumentIcon } from "./DocumentIcon";
import { getQueryParameterMap } from "./common-utils";
import { DocumentLinkCopyButton, DocumentPreviewButton } from "../container/ContainerButtons";
import "../../styles/component/document-group.scss";

export const DocumentDownload = (props: {
    document: Document;
}) => {
    const context = React.useContext(AppContext);
    const getObjectPresignedUrlRunner = useAPI(
        context.getFileAPI().getPresignedUrl
    );
    const [isDownloaded, setIsDownloaded] = React.useState<boolean>(false);
    const [downloadInProgress, setDownloadInProgress] = React.useState<boolean>(false);
    const [downloadURL, setDownloadURL] = React.useState<string>();
    const fileExtension = DocumentFactory.getDocumentExtension(props.document.filename);
    const filePreview = (AcceptedPreviewExtension.includes(fileExtension));

    const gridDefinitionPreview = [{ colspan: 1 }, { colspan: 1 }];
    const gridDefinitionNoPreview = [{ colspan: 3, offset: 2 }];

    const downloadLinkRef = React.useRef(null);

    const fetchPreSignedURL = () => {
        setDownloadInProgress(true);
        getObjectPresignedUrlRunner.submitRun(
            IFile.PresignedUrlInput.create({
                key: props.document.s3File.key,
                operation: IFile.PresignedOperation.Get,
                versionId: props.document.s3File.versionId
            })
        );
    };

    const documentComponent = <div>
        <div className="document-download-flex" onClick={fetchPreSignedURL}>
            <div className={`${downloadInProgress ? "mask" : ""}`} />
            {<DocumentIcon filename={props.document.filename} />}
            <div className={`document-info ${isDownloaded ? "downloaded" : ""}`}>
                <h6>{props.document.filename}</h6>
                <p>{props.document.description}</p>
            </div>
        </div>
        <a ref={downloadLinkRef} href={downloadURL} className="hide-link" download />
    </div>;

    React.useEffect(() => {
        const queryParamMap = getQueryParameterMap();
        if (!!queryParamMap.get("container") && !!queryParamMap.get("document")) {
            const queryContainerId = queryParamMap.get("container");
            const encodedQueryDocumentName = DocumentFactory.encodeDocumentName(queryParamMap.get("document"));
            const containerId = S3File.AskLegalFileKey.toEntityIdFromKey(props.document.s3File.key, EntityType.Container);
            const encodedDocumentName = DocumentFactory.encodeDocumentName(props.document.filename);
            if (containerId === queryContainerId && encodedDocumentName === encodedQueryDocumentName) fetchPreSignedURL();
        }
    }, []);

    React.useEffect(() => {
        if (getObjectPresignedUrlRunner.status === "Succeeded") {
            setDownloadInProgress(false);
            setDownloadURL(getObjectPresignedUrlRunner.data.output.presignedUrl);
        }
        if (getObjectPresignedUrlRunner.status === "Error") {
            setDownloadInProgress(false);
        }
    }, [getObjectPresignedUrlRunner.status]);

    React.useEffect(() => {
        if (!downloadURL) return;
        downloadLinkRef.current.click();
        setIsDownloaded(true);
        setDownloadURL(null);
    }, [downloadURL]);

    return (
        <React.Fragment>
            {getObjectPresignedUrlRunner.status === "Error" &&
                <StatusIndicator type="error">An error occured, please try again</StatusIndicator>}
            <Grid gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}>
                {documentComponent}
                <Grid gridDefinition={filePreview ? gridDefinitionPreview : gridDefinitionNoPreview}>
                    {(filePreview) && (<DocumentPreviewButton document={props.document} />)}
                    <DocumentLinkCopyButton document={props.document} />
                </Grid>
            </Grid>
        </React.Fragment >
    );
};
