import * as React from "react";
import {
    Box,
    ColumnLayout,
    Spinner,
    StatusIndicator,
    Tabs,
    TabsProps
} from "@amzn/awsui-components-react";
import { useAPI } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import { Navigation, INavigation } from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import "../../styles/component/layout/NavigationFooter.scss";
import "../../styles/home-page/responsive.scss";

export const NavigationWidget = (props: {
    instanceId: string;
}) => {
    const context = React.useContext(AppContext);
    const loadNavigationRunner = useAPI(
        context.getNavigationAPI().load
    );

    React.useEffect(() => {
        if (!props.instanceId) return;
        loadNavigationRunner.submitRun(
            INavigation.LoadNavigationInput.create({
                navigationId: props.instanceId
            })
        );
    }, [props.instanceId]);

    return (<React.Fragment>
        <div className="home-page-sub-header">
            <p className="title">Browse</p>
        </div>
        <React.Fragment>
            {loadNavigationRunner.status === "Error" &&
                <Box textAlign="center">
                    <StatusIndicator type="error">Error while loading</StatusIndicator>
                </Box>
            }
            {loadNavigationRunner.status === "Running" && <Spinner size="large" variant="inverted" />}
            {loadNavigationRunner.status === "Succeeded" &&
                <div>
                    <Tabs
                        className="awsui-polaris-dark-mode"
                        tabs={loadNavigationRunner.data.output.tabs.map((tab) => {
                            return Builder<TabsProps.Tab>()
                                .id(tab.id)
                                .label(tab.name)
                                .content(<NavigationTabContent
                                    content={tab.items}
                                />)
                                .disabled(false)
                                .build();
                        })}
                    />
                </div>}
        </React.Fragment>
    </React.Fragment>);
};

export const NavigationTabContent = (props: {
    content: Navigation.NavGroup[]
}) => {
    const [segments, setSegments] = React.useState<Navigation.NavGroup[][]>();
    const [activeNavGroup, setActiveNavGroup] = React.useState<string>(null);
    const [activeNavLink, setActiveNavLink] = React.useState<string>(null);

    const handleActiveNavGroup = (id: string) => {
        if (activeNavGroup === id) {
            setActiveNavGroup(null);
        } else {
            setActiveNavGroup(id);
        }
    };

    const handleActiveNavLink = (id: string) => {
        if (activeNavLink === id) {
            setActiveNavLink(null);
        } else {
            setActiveNavLink(id);
        }
    };

    const empty = (
        <Box textAlign="center" color="inherit" margin={{ top: "xxl" }}>
            <b>
                <em>
                    Nothing to display
                </em>
            </b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                <em>
                    Nothing to display here
                </em>
            </Box>
        </Box>
    );

    React.useEffect(() => {
        setSegments(getNavigationColumns(props.content));
    }, []);

    return (
        <div >
            {(props.content.length === 0 || !segments || segments.length !== 3) && empty}
            {props.content.length > 0 && !!segments && segments.length === 3 &&
                <div className="navtab-content">
                    {getNavigationTabContent({
                        tabItems: props.content,
                        activeNavGroup: activeNavGroup,
                        activeNavLink: activeNavLink,
                        handleActiveNavGroup: handleActiveNavGroup,
                        handleActiveNavLink: handleActiveNavLink
                    })}
                </div>
            }
        </div>
    );
};

export function getNavigationTabContent (props: {
    tabItems: Navigation.NavGroup[],
    activeNavGroup: string,
    activeNavLink: string,
    handleActiveNavGroup: (navGroup: string) => void,
    handleActiveNavLink: (navLink: string) => void
}) {
    return (<ColumnLayout columns={3}>
        {getNavigationColumns(props.tabItems).map((nestedGroups) =>
            <li className="navgroup">
                <ColumnLayout columns={1} disableGutters>
                    {nestedGroups.map((group) => getNavigationGroupContent({
                        navGroup: group,
                        activeNavGroup: props.activeNavGroup,
                        activeNavLink: props.activeNavLink,
                        handleActiveNavGroup: props.handleActiveNavGroup,
                        handleActiveNavLink: props.handleActiveNavLink
                    }))}
                </ColumnLayout>
            </li>
        )}
    </ColumnLayout>);
}

export function getNavigationGroupContent (props: {
    navGroup: Navigation.NavGroup;
    activeNavGroup: string,
    activeNavLink: string,
    handleActiveNavGroup: (navGroup: string) => void,
    handleActiveNavLink: (navLink: string) => void
}) {
    let topLevelContent = props.navGroup.nested.length > 0 ?
        <button
            className={
                `navgroup-action ${props.activeNavGroup === props.navGroup.id ?
                    "navgroup-action-clicked" : ""}`
            }
            onClick={() => props.handleActiveNavGroup(props.navGroup.id)}
            id={props.navGroup.id}
            aria-expanded={props.activeNavGroup === props.navGroup.name}
            aria-controls={props.navGroup.name}>
            <div className="navgroup-action-button">
                <FontAwesomeIcon
                    icon={
                        props.activeNavGroup === props.navGroup.id ?
                        faTimes : faPlus
                    }
                />
            </div>
            <p>{props.navGroup.name}</p>
        </button> :
        props.navGroup.url ?
            <button
                className={
                    `navgroup-action ${props.activeNavGroup === props.navGroup.id ?
                    "navgroup-action-clicked" : ""}`
                }>
                <a
                    className="navgroup-action-text"
                    href={props.navGroup.url}
                    target={
                        props.navGroup.url.includes(window.location.origin) ?
                        "_self" : "_blank"
                    }>
                    {props.navGroup.name}
                </a>
            </button> :
            <button className="navgroup-action">
                <p className="navgroup-action-text disabled">
                    {props.navGroup.name}
                </p>
            </button>;

    let nestedContent = props.navGroup.nested.length > 0 && (
    <div
        id={props.navGroup.name}
        className={
            `navgroup-nested ${props.activeNavGroup === props.navGroup.id ?
                "navgroup-nested-display" : ""}`
            }
        aria-expanded={false}
        aria-controlledby={props.navGroup.id}>
        {props.navGroup.url && (
            <a href={props.navGroup.url} target={props.navGroup.url.includes(window.location.origin) ? "_self" : "_blank"}>
                <strong>{props.navGroup.name}</strong>
            </a>
        )}
        {props.navGroup.nested.map(nestedLink => getNavigationLinkContent({
            navLink: nestedLink,
            activeNavLink: props.activeNavLink,
            handleActiveNavLink: props.handleActiveNavLink
        }))}
    </div>);

    return <>
        {topLevelContent}
        {nestedContent}
    </>;
}

export function getNavigationLinkContent (props: {
    navLink: Navigation.NavLink,
    activeNavLink: string,
    handleActiveNavLink: (navLink: string) => void
}) {
    let topLevelNavLink = props.navLink.nested?.length > 0 ?
        <button
            className={
                `navgroup-action ${props.activeNavLink === props.navLink.id ?
                    "navgroup-action-clicked" : ""}`
                }
            onClick={() => props.handleActiveNavLink(props.navLink.id)}
            id={props.navLink.id}
            aria-expanded={props.activeNavLink === props.navLink.name}
            aria-controls={props.navLink.name}>
            <div className="navgroup-action-button">
                <FontAwesomeIcon
                    icon={props.activeNavLink === props.navLink.id ?
                    faTimes : faPlus}
                />
            </div>
            <p>{props.navLink.name}</p>
        </button> :
        props.navLink.url ?
            <button
                className={
                    `navgroup-action ${props.activeNavLink === props.navLink.id ?
                        "navgroup-action-clicked" : ""}`
                    }>
                <a
                    className="navgroup-action-text"
                    href={props.navLink.url}
                    target={props.navLink.url.includes(window.location.origin) ?
                        "_self" : "_blank"}>
                    {props.navLink.name}
                </a>
            </button> :
            <button className="navgroup-action">
                <p className="navgroup-action-text disabled">
                    {props.navLink.name}
                </p>
            </button>;

    let nestedNavLink = props.navLink.nested?.length > 0 && (
        <div
            id={props.navLink.name}
            className={`navgroup-nested ${props.activeNavLink === props.navLink.id ? "navgroup-nested-display" : ""}`}
            aria-expanded={false}
            aria-controlledby={props.navLink.id}>
                {props.navLink.url && (
                    <a href={props.navLink.url} target={props.navLink.url.includes(window.location.origin) ? "_self" : "_blank"}>
                        <strong>{props.navLink.name}</strong>
                    </a>
                )}
                {props.navLink.nested.map(nestedSubLink =>
                    <li>{nestedSubLink.url ?
                        <a href={nestedSubLink.url}
                        target={nestedSubLink.url.includes(window.location.origin) ? "_self" : "_blank"}>
                            {nestedSubLink.name}</a> :
                        <p className="disabled">
                            {nestedSubLink.name}
                        </p>}
                    </li>
                )}
        </div>
    );

    return <>
        {topLevelNavLink}
        {nestedNavLink}
    </>;
}

export function getNavigationColumns(items: Navigation.NavGroup[]) {
    let len1 = 0, len2 = 0, len3 = 0, idx = 0;
    while (idx <= items.length) {
        if (idx === items.length) break;
        if (len1 === len2 && len1 === len3) {
            len1 = len1 + 1;
        } else if (len1 > len2 && len2 === len3) {
            len2 = len2 + 1;
        } else if (len1 === len2 && len2 > len3) {
            len3 = len3 + 1;
        }
        idx += 1;
    }
    return [items.slice(0, len1), items.slice(len1, len1 + len2), items.slice(len1 + len2)];
}