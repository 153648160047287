import { Team, TeamInterface, Input } from "@amzn/ask-legal-domain";
import { RestAPI } from "@aws-amplify/api-rest";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface TeamAPI {
    searchTeam(input: TeamInterface.SearchTeamInput): Promise<APIResponse<Team[]>>;
}

export class TeamAPIImpl implements TeamAPI {
    constructor (private context: AppContextValue) {}

    async searchTeam (
        input: TeamInterface.SearchTeamInput
    ): Promise<APIResponse<Team[]>> {
        const response: APIResponse<Team[]> = await RestAPI.post(
            "custom-domain",
            "/team/search",
            toDefaultRequestPayload(input)
        );
        return response;
    }
}
