import {
  AdvancedList, CreateAdvancedListItemCommandInput, EntityRef,
  FieldDefinition,
  FieldDefinitionDataType,
  IdentityRef
} from "@amzn/altar-sds-client";
import {
  FormField,
  FormFieldProps, Input,
  InputProps, SpaceBetween
} from "@amzn/awsui-components-react";
import * as React from "react";

export function CreateAdvancedListItemCommandInputEdit(props: {
  listRef: EntityRef;
  advancedList: AdvancedList;
  by: IdentityRef;
  value?: CreateAdvancedListItemCommandInput;
  onChanged?: (value?: CreateAdvancedListItemCommandInput) => void;
}) {
  const [formFieldPropsList, setFormFieldPropsList] = React.useState<
    (Pick<FormFieldProps, "label" | "errorText"> & { fieldDef: FieldDefinition; })[]
  >(
    props.advancedList.fieldDefinitions.map((e) => ({
      label: e.displayName,
      fieldDef: e
    }))
  );

  const [inputPropsList, setInputPropsList] = React.useState<(Pick<InputProps, "value"> & { fieldKey: string; })[]>(
    props.advancedList.fieldDefinitions
      .filter((e) => e.dataType === FieldDefinitionDataType.string)
      .map((e) => ({
        value: (props.value?.values?.find((v) => v.key === e.fieldKey && v.type === e.dataType)?.value as string) || "",
        fieldKey: e.fieldKey
      }))
  );

  function updateInputPropsValue(fieldKey: string, value?: string) {
    setInputPropsList((prev) => prev.map((e) => {
      if (e.fieldKey !== fieldKey) return e;
      return {
        ...e,
        value: value
      };
    })
    );
  }

  function updateFormFieldProps(fieldKey: string, value?: Pick<FormFieldProps, "label" | "errorText">) {
    setFormFieldPropsList((prev) => prev.map((e) => {
      if (e.fieldDef.fieldKey !== fieldKey) return e;
      return {
        ...e,
        ...value
      };
    })
    );
  }

  function validate() {
    let valid = true;
    for (const inputProps of inputPropsList) {
      const formFieldProps = formFieldPropsList.find((e) => e.fieldDef.fieldKey === inputProps.fieldKey);
      if (formFieldProps.fieldDef.required && !inputProps.value) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required"
        });
        valid = false;
        continue;
      }
      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined
      });
    }

    let ret: CreateAdvancedListItemCommandInput | undefined;
    if (valid) {
      ret = {
        repositoryId: props.listRef.repositoryRef.repositoryId,
        entityId: props.listRef.entityId,
        by: props.by,
        values: inputPropsList
          .filter((e) => e.value)
          .map((e) => ({
            key: e.fieldKey,
            type: FieldDefinitionDataType.string,
            value: e.value
          }))
      };
    }

    props.onChanged(ret);
  }

  React.useEffect(() => {
    validate();
  }, [inputPropsList]);

  return (
    <SpaceBetween size={"s"}>
      {formFieldPropsList.map((formFieldProps) => (
        <FormField {...formFieldProps}>
          {inputPropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <Input
                {...e}
                onChange={(event) => {
                  updateInputPropsValue(e.fieldKey, event.detail.value);
                }} />
            ))}
        </FormField>
      ))}
    </SpaceBetween>
  );
}
