import { PageDraft } from "@amzn/ask-legal-domain";
import {
    Button,
    ColumnLayout,
    ExpandableSection,
    Header,
    Link,
    Popover,
    SpaceBetween,
    TextContent
} from "@amzn/awsui-components-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { PageDraftModel } from "../../model/page-draft-model";
import { PageModel } from "../../model/page-model";
import { UIModel } from "../../model/ui-model";
import { AppContext } from "../../setup/context";
import { DateTimeFormatter } from "../common/DateTimeFormatter";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { IdentityLink } from "../common/IdentityLink";
import { UIField } from "../common/UIField";
import { PageLink } from "../page/PageLink";
import "../../styles/util-styles.scss";

export const PageDraftGeneralInfo = (props: {
    state: UIModel.State<PageDraft.Data>
}) => {
    const context = React.useContext(AppContext);
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [editing, setEditing] = React.useState<boolean>(false);
    const [expandFlag_1, setExpandFlag_1] = React.useState<boolean>(false);
    const [expandFlag_2, setExpandFlag_2] = React.useState<boolean>(false);
    const updateDraftMetaState = PageDraftModel.UpdateDraftMetaState.use({
        draft: props.state.value
    });
    const updateDraftMetaRunner = useAPI(
        context.getPageDraftAPI().updateMeta
    );

    const updateMeta = () => {
        updateDraftMetaRunner.submitRun(
            PageDraftModel.UpdateDraftMetaState.toInput(
                updateDraftMetaState
            )
        );
    };

    React.useEffect(() => {
        updateDraftMetaState.setDraft(props.state.value);
    }, [
        props.state.value.id,
        props.state.value.version
    ]);

    React.useEffect(() => {
        if (updateDraftMetaRunner.status === "Succeeded") {
            props.state.setValue(updateDraftMetaRunner.data.output);
            setEditing(false);
        }
        if (updateDraftMetaRunner.status === "Error") {
            setEditing(false);
        }
    }, [updateDraftMetaRunner.status]);

    React.useEffect(() => {
        let currentExpanded = expanded;
        if (!!expandFlag_1) {
            currentExpanded = !currentExpanded;
            setExpandFlag_1(false);
        }
        if (!!expandFlag_2) {
            currentExpanded = !currentExpanded;
            setExpandFlag_2(false);
        }
        if (!editing) setExpanded(currentExpanded);
    }, [expandFlag_1, expandFlag_2]);

    const getPageDraftMetadata = () => {
        return (
            <ColumnLayout columns={4} variant="text-grid">
                <SpaceBetween size="s">
                    <UIField.LabelField
                        label={"Base Page"}>
                        <PageLink.Live id={props.state.value.pageId} />
                    </UIField.LabelField>
                    <UIField.LabelField
                        label={"Current Version"}>
                        {props.state.value.version}
                    </UIField.LabelField>
                    <UIField.LabelField
                        label={"Draft Status"}>
                        {props.state.value.status}
                    </UIField.LabelField>
                </SpaceBetween>
                <SpaceBetween size="s">
                    <UIField.LabelField
                        label={"Created By"}>
                        <IdentityLink
                            id={props.state.value.createdBy.id}
                            name={props.state.value.createdBy.name}
                        />
                    </UIField.LabelField>
                    <UIField.LabelField
                        label={"Creation Date"}>
                        {DateTimeFormatter.formatDate(props.state.value.creationDate)}
                    </UIField.LabelField>
                </SpaceBetween>
                <SpaceBetween size="s">
                    <UIField.LabelField
                        label={"Last Modified By"}>
                        <IdentityLink
                            id={props.state.value.lastModifiedBy.id}
                            name={props.state.value.lastModifiedBy.name}
                        />
                    </UIField.LabelField>
                    <UIField.LabelField
                        label={"Last Modified Date"}>
                        {DateTimeFormatter.formatDate(props.state.value.lastModifiedDate)}
                    </UIField.LabelField>
                    <UIField.LabelField
                        label={"Last Modified Comment"}>
                        {props.state.value.lastModifiedCommit}
                    </UIField.LabelField>
                </SpaceBetween>
                <SpaceBetween size="s">
                    <UIField.LabelField
                        label={"Draft Reviewed By"}>
                        {props.state.value.reviewedBy ?
                            <IdentityLink
                                id={props.state.value.reviewedBy.id}
                                name={props.state.value.reviewedBy.name}
                            /> : null
                        }
                    </UIField.LabelField>
                    <UIField.LabelField
                        label={"Draft Contributors"}>
                        {props.state.value.contributors?.map(c =>
                            <IdentityLink
                                id={c.id}
                                name={c.name}
                            />
                        )}
                    </UIField.LabelField>
                </SpaceBetween>
            </ColumnLayout>
        );
    };

    const getActionButtons = () => {
        if (props.state.value.status !== "DRAFT") {
            return null;
        }
        return (
            <div>
                {editing && (
                    <SpaceBetween direction="horizontal" size="s">
                        <Button
                            variant="secondary"
                            loading={updateDraftMetaRunner.status === "Running"}
                            onClick={() => {
                                updateDraftMetaState.reset();
                                setEditing(false);
                            }}>Cancel
                        </Button>
                        <Button
                            variant="primary"
                            disabled={
                                updateDraftMetaState.newPageTitleField.errorText ||
                                updateDraftMetaState.newPageDescription.errorText
                            }
                            loading={updateDraftMetaRunner.status === "Running"}
                            onClick={updateMeta}>Save
                        </Button>
                    </SpaceBetween>
                )}
                {expanded && !editing && (
                    <Button
                        variant="normal"
                        loading={updateDraftMetaRunner.status === "Running"}
                        onClick={() => {
                            setEditing(true);
                            setExpanded(true);
                        }}>Edit Page Info
                    </Button>
                )}
            </div>
        );
    };

    const [descriptionConstraint, setDescriptionConstraint] = React.useState<React.ReactNode>(null);

    React.useEffect(() => {
        if (!updateDraftMetaState.newPageDescription.value || (PageModel.DESCRIPTION_CHAR_LIMIT - updateDraftMetaState.newPageDescription.value.length === PageModel.DESCRIPTION_CHAR_LIMIT)) {
            setDescriptionConstraint(`Maximum ${PageModel.DESCRIPTION_CHAR_LIMIT} characters`);
        } else if (PageModel.DESCRIPTION_CHAR_LIMIT - updateDraftMetaState.newPageDescription.value.length >= 0) {
            setDescriptionConstraint(`Maximum ${PageModel.DESCRIPTION_CHAR_LIMIT} characters ( ${PageModel.DESCRIPTION_CHAR_LIMIT - updateDraftMetaState.newPageDescription.value.length} remaining )`);
        } else if (PageModel.DESCRIPTION_CHAR_LIMIT - updateDraftMetaState.newPageDescription.value.length < 0) {
            setDescriptionConstraint(
                <TextContent>
                    <small>
                        Maximum {PageModel.DESCRIPTION_CHAR_LIMIT} characters ( <span style={{ color: "#d13212" }}>{updateDraftMetaState.newPageDescription.value.length - PageModel.DESCRIPTION_CHAR_LIMIT}</span> too many )
                    </small>
                </TextContent>
            );
        }
    }, [updateDraftMetaState.newPageDescription.value]);

    return (
        <ExpandableSection
            variant="container"
            expanded={expanded}
            className="custom-container"
            onChange={() => setExpandFlag_1(true)}
            header={
                <Header
                    actions={
                        <span onClick={() => setExpandFlag_2(true)}>
                            {getActionButtons()}
                        </span>}
                    info={
                        <span onClick={() => setExpandFlag_2(true)}>
                            <Popover
                                position="bottom"
                                size="large"
                                triggerType="custom"
                                header={"Page Draft Metadata"}
                                content={getPageDraftMetadata()}>
                                <span title="info" style={{ color: "white", cursor: "pointer" }}>
                                    <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                                </span>
                            </Popover>
                        </span>
                    }>
                    <p className="dark-header-p">
                        General Info
                    </p>
                </Header>
            }>
            {updateDraftMetaRunner.status === "Error" && <ErrorFlashbar error={updateDraftMetaRunner.data.err} />}
            <ColumnLayout columns={2} variant="text-grid">
                <SpaceBetween size="s">
                    <UIField.LabelField
                        label={"Live Page Link"}>
                        <PageLink.Live id={props.state.value.pageId} />
                    </UIField.LabelField>
                </SpaceBetween>
                <SpaceBetween size="s">
                    <UIField.StateValueField
                        name="Page Title"
                        state={updateDraftMetaState.newPageTitleField}
                        constraintText={!!editing && `Maximum ${PageModel.TITLE_CHAR_LIMIT} characters`}
                        editing={editing}
                    />
                    <UIField.StateValueField
                        name="Page Description"
                        state={updateDraftMetaState.newPageDescription}
                        constraintText={!!editing && descriptionConstraint}
                        editing={editing}
                        variant="TextArea"
                    />
                </SpaceBetween>
            </ColumnLayout>
        </ExpandableSection>
    );
};
