import { useHistory, useLocation, useParams } from "react-router-dom";
import * as React from "react";
import { AppContext } from "../../setup/context";
import { useAPI2 } from "../../hooks/api-hook";
import { ContainerRef, DecisionTreeContent, EntityFactory, EntityRef, EntityType, PageFactory } from "@amzn/ask-legal-domain";
import { Button, SpaceBetween, TextContent } from "@amzn/awsui-components-react";
import { StringUtils } from "../common/common-utils";
import { CustomEdge } from "./cusom-resources/CustomEdge";
import { CustomNode } from "./cusom-resources/CustomNode";
import { NodeEditModal } from "./node-editor/NodeEditModal";
import ReactFlow, {
    Controls,
    MiniMap,
    Background,
    BackgroundVariant,
    Panel,
    FitViewOptions,
    ReactFlowProvider,
} from "reactflow";
import { useAutoLayout } from "../../hooks/decision-tree-node-hook";
import { ConfirmLeavePageModal } from "../common/ConfirmLeavePageModal";
import "../../styles/component/decision-tree/editor.scss";

export namespace DecisionTreeEdit {
    /**
     * This main component loads container and decision tree content, and renders the editor.
     */
    export const Comp = () => {
        const context = React.useContext(AppContext);
        const { containerId } = useParams<{ containerId: string }>();
        const params = new URLSearchParams(useLocation().search);
        const pageDraftId = params.get("draftId");
        const pageDraftVersion = parseInt(params.get("draftVersion"));
        const loadContainerRunner = useAPI2(context.getContainerAPI().load);

        React.useEffect(() => {
            loadContainerRunner.invoke(containerId);
        }, [containerId]);

        return <div>
            {loadContainerRunner.status === "Succeeded" &&
                <ReactFlowEditor
                    initialContent={loadContainerRunner.output.loadedContent as DecisionTreeContent}
                    draft={EntityFactory.fromEntityAttributes(pageDraftId, EntityType.PageDraft, pageDraftVersion)}
                    containerRef={loadContainerRunner.output.containerMetadata}
                />
            }
        </div>;
    };

    export const ReactFlowEditor = (props: {
        draft: EntityRef,
        containerRef: ContainerRef;
        initialContent: DecisionTreeContent
    }) => {
        const history = useHistory();
        const [editingNodeId, setEditingNodeId] = React.useState<string>("");
        const autoLayout = useAutoLayout({content: props.initialContent});
        const reactFlowWrapper = React.useRef(null);

        const fitViewOptions: FitViewOptions = { padding: 0.2 };
        const nodeTypes = React.useMemo(() => CustomNode.List, []);
        const edgeTypes = React.useMemo(() => CustomEdge.List, []);

        const closeEditor = () => {
            history.push(`/page/${
                PageFactory.fromEntityId(props.containerRef.id)
            }/admin?tab=draftPage&container=${
                props.containerRef.id
            }`);
        };

        return <>
            <div style={{ width: "auto", height: "87vh", border: "solid"}}>
                <ReactFlowProvider>
                <ReactFlow
                    nodes={autoLayout.nodes}
                    edges={autoLayout.edges}
                    onNodeClick={(event, node) => setEditingNodeId(node.id) }
                    fitView
                    fitViewOptions={fitViewOptions}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}>
                    <Panel position="top-left"><SpaceBetween direction="horizontal" size="s">
                        <Button onClick={closeEditor} variant="primary">Go Back to Page Draft</Button>
                    </SpaceBetween></Panel>
                    <Panel position="top-right">
                        <TextContent>
                            <h3>Helper</h3>
                            <ul>
                                <li>Click on a node to start editing</li>
                                <li><span style={{ color: "green" }}>Green node</span> is the starting entry point</li>
                                <li><span style={{ color: "red" }}>Red nodes</span> are the terminating states</li>
                            </ul>
                        </TextContent>
                    </Panel>
                    <Controls />
                    <MiniMap
                        zoomable
                        pannable
                        nodeColor={(n) => {
                            if (n.id === props.initialContent.rootNodeId) return "#229954";
                            return "#E78C8C";
                        }}
                        nodeStrokeColor={(n) => {
                            if (n.id === props.initialContent.rootNodeId) return "#229954";
                            return "#E78C8C";
                        }}
                    />
                    <Background variant={BackgroundVariant.Lines} gap={12} size={1} />
                </ReactFlow></ReactFlowProvider>
            </div>
            { !StringUtils.isEmpty(editingNodeId) && <>
                <ConfirmLeavePageModal showModal={true} />
                <NodeEditModal
                    draftRef={props.draft}
                    nodeId={editingNodeId}
                    containerId={props.containerRef.id}
                    onDismiss={() => setEditingNodeId("")}
                    onSucceed={updatedContent => {
                        // Update UI layout
                        autoLayout.update(updatedContent);
                        // Close the modal
                        setEditingNodeId("");
                    }}
                />
            </>}
        </>;
    };
}