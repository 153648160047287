import {
    Button,
    FormField,
    Header,
    Input,
    SpaceBetween,
    Table
} from "@amzn/awsui-components-react";
import * as React from "react";
import { ConfigurableOption, PrimitiveDataFactory } from "@amzn/ask-legal-domain";
import { UIModel } from "../../model/ui-model";

const INPUT_LIMIT = 200;
const DUPLICATE_VALIDATION_MESSAGE = "Value already exists, please check below";
const CHAR_LIMIT_VALIDATION_MESSAGE = "Exceeded character limit of 200";

export const ConfigurableOptionsInput = (props: {
    headerTitle: string;
    state: UIModel.State<ConfigurableOption[]>;
    onChange: (updated: ConfigurableOption[]) => void;
}) => {
    const [selected, setSelected] = React.useState<ConfigurableOption>();

    const addOptionState = UIModel.State.use<string>({
        initialValue: "",
        validation: (t: string) => {
            if (t.length > INPUT_LIMIT) {
                return CHAR_LIMIT_VALIDATION_MESSAGE;
            }
            if (props.state.value?.find(o => o.displayValue === t)) {
                return DUPLICATE_VALIDATION_MESSAGE;
            }
        }
    });

    const isValidOption = () => {
        return addOptionState.value.length > 0 &&
            !props.state.value?.find(o => o.displayValue === addOptionState.value) &&
            addOptionState.value.length <= INPUT_LIMIT;
    };

    React.useEffect(() => {
        props.onChange(props.state.value);
    }, [props.state.value]);

    const onItemAdd = () => {
        if (!isValidOption()) return;
        if (addOptionState.value.trim().length > 0) {
            props.state.setValue([
                ...props.state.value,
                {
                    id: PrimitiveDataFactory.id(),
                    displayValue: addOptionState.value.trim()
                }
            ]);
            addOptionState.setValue("");
        }
    };

    const addEntryComp = <FormField
        errorText={addOptionState.errorText}
        secondaryControl={
            <Button
                disabled={!isValidOption()}
                variant="primary"
                iconName="add-plus"
                onClick={onItemAdd}
            >
                Add Option
            </Button>
        }
    >
        <Input
            placeholder={"Add option"}
            value={addOptionState.value}
            onChange={({ detail }) => addOptionState.setValue(detail.value)}
            onKeyDown={({ detail }) => {
                if (detail.keyCode === 13) {
                    onItemAdd();
                }
            }}
        />
    </FormField>;

    const tableView = (values: ConfigurableOption[]) => {
        return <>
            <Table
                header={
                    <Header
                        actions={
                            <Button
                                onClick={() => {
                                    props.state.setValue(
                                        props.state.value?.map(oldOption => {
                                            if (oldOption.id === selected.id) {
                                                return {
                                                    ...oldOption,
                                                    deprecated: !selected.deprecated
                                                };
                                            } else {
                                                return oldOption;
                                            }
                                        })
                                    );
                                }}
                            >
                                {selected?.deprecated ? "Restore" : "Deprecate"}
                            </Button>
                        }
                    >
                        {props.headerTitle}
                    </Header>
                }
                selectionType="single"
                onSelectionChange={(e) => {
                    setSelected(e.detail.selectedItems[0]);
                }}
                selectedItems={[selected]}
                items={values}
                columnDefinitions={[
                    {
                        id: "displayValue",
                        header: "Display Value",
                        cell: (i) => i.displayValue,
                        editConfig: {
                            editingCell: (item, { currentValue, setValue }) => {
                                const value = currentValue ?? item.displayValue;
                                return (
                                    <Input
                                        autoFocus={true}
                                        value={value}
                                        expandToViewport={true}
                                        onChange={(e) => {
                                            setValue(e.detail.value);
                                        }}
                                    />
                                );
                            },
                            validation: (item, value) => {
                                if (value?.length > INPUT_LIMIT) {
                                    return CHAR_LIMIT_VALIDATION_MESSAGE;
                                }
                                if (
                                    props.state.value.find(o => o.displayValue === value) &&
                                    value !== item.displayValue
                                ) {
                                    return DUPLICATE_VALIDATION_MESSAGE;
                                }
                            },
                        }
                    },
                    {
                        id: "deprecated",
                        header: "Active",
                        cell: (i) => !i.deprecated ? "Yes" : "No",
                        maxWidth: "60px"
                    }
                ]}
                submitEdit={(item, column, newValue: string) => {
                    if (
                        (
                            props.state.value.find(o => o.displayValue === newValue) && newValue !== item.displayValue
                        ) ||
                        newValue.length > 200
                    ) {
                        // invalid input; no update
                        return;
                    }
                    props.state.setValue(props.state.value.map(oldOption => {
                        if (oldOption.id === item.id) {
                            return { ...oldOption, displayValue: newValue as string };
                        } else {
                            return oldOption;
                        }
                    }));
                }}
                empty="No options configured"
                resizableColumns={false}
            />
        </>;
    };

    return (
        <SpaceBetween size="l">
            {addEntryComp}
            {tableView(props.state.value)}
        </SpaceBetween>
    );
};