import { FilterField, IPage, Page, SortingField } from "@amzn/ask-legal-domain";
import { Box, Link, PropertyFilterProps, SpaceBetween, StatusIndicator, TableProps } from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import * as React from "react";
import { DateTimeFormatter } from "../../components/common/DateTimeFormatter";
import { IdentityLink } from "../../components/common/IdentityLink";
import { LegalOnlyBadgeComp } from "../../components/common/LegalOnlyTag";
import { CommonPolarisFactory } from "./common-polaris-factory";

export namespace PagePolarisFactory {
    export namespace Table {
        export namespace PropertyFilter {
            export const Name = Builder<PropertyFilterProps.FilteringProperty>()
                .key(FilterField.Name)
                .operators(["="])
                .propertyLabel("Title")
                .build();
            export const PageFreshness = Builder<PropertyFilterProps.FilteringProperty>()
                .key(FilterField.PageFreshnessStatus)
                .operators(["="])
                .propertyLabel("Freshness Status")
                .groupValuesLabel("Available Status")
                .build();
            export const Deprecated = Builder<PropertyFilterProps.FilteringProperty>()
                .key(FilterField.Deprecated)
                .operators(["="])
                .propertyLabel("Is Deprecated?")
                .groupValuesLabel("Available Values")
                .build();
            export const Any = Builder<PropertyFilterProps.FilteringProperty>()
                .key(FilterField.Any)
                .operators(["="])
                .propertyLabel("Any")
                .build();
            export function toFilterPages() {
                return [
                    Any,
                    Name,
                    PageFreshness,
                    Deprecated
                ];
            }
        }

        export const PageLibraryRef = Builder<TableProps.ColumnDefinition<Page.Data>>()
            .id("pageLibraryRef")
            .header("PageLibrary")
            .cell((item: IPage.LoadAllPagesByPageLibraryOutput) => item.pageLibraryId)
            .build();
        export const PageTitle = Builder<TableProps.ColumnDefinition<Page.Data>>()
            .id("pageTitle")
            .header("Title")
            .sortingField(SortingField.Title)
            .cell((item: IPage.LoadAllPagesByPageLibraryOutput) => (
                <React.Fragment>
                    <Link variant="secondary" href={`#/page/${item.id}/admin`}>
                        {item.title}
                    </Link>
                    {!!item.isLegalOnly && LegalOnlyBadgeComp}
                </React.Fragment>
            ))
            .build();
        export const PageOwner = Builder<TableProps.ColumnDefinition<Page.Data>>()
            .id("pageOwner")
            .header("Owner")
            .cell((item: IPage.LoadAllPagesByPageLibraryOutput) => {
                if (!!item.pageOwner) {
                    return <IdentityLink id={item.pageOwner.id} name={item.pageOwner.name} />;
                } else {
                    return <Box variant="small" textAlign="center" margin={{ left: "xxxl" }}>-</Box>;
                }
            })
            .build();
        export const PageFreshness = Builder<TableProps.ColumnDefinition<Page.Data>>()
            .id("pageFreshnessDate")
            .header("Freshness Date (Status)")
            .sortingField(SortingField.PageFreshnessDate)
            .cell((item: IPage.LoadAllPagesByPageLibraryOutput) => {
                return <SpaceBetween size="s" direction="horizontal">
                    {!!item.freshnessDate && DateTimeFormatter.formatDate(item.freshnessDate)}
                    {item.freshnessStatus === Page.Freshness.FRESH && <StatusIndicator>Fresh</StatusIndicator>}
                    {item.freshnessStatus === Page.Freshness.EXPIRED && <StatusIndicator type="error">Expired</StatusIndicator>}
                </SpaceBetween>;
            })
            .build();
        export const PageDescription = Builder<TableProps.ColumnDefinition<Page.Data>>()
            .id("pageDescription")
            .header("Description")
            .cell((item: IPage.LoadAllPagesByPageLibraryOutput) => {
                if (!!item.description) {
                    return <Box variant="small" textAlign="center" margin={{ left: "xxxl" }}>{item.description}</Box>;
                }
            })
            .build();
        export function toColumnDefinitions() {
            return CommonPolarisFactory.Table.ColumnDefinition.toSortedColumnDefinition([
                PageTitle,
                PageOwner,
                PageFreshness,
                PageLibraryRef
            ]);
        }
    }
}