import * as React from "react";
import { UIModel } from "../../../model/ui-model";
import {
    Button,
    Grid,
    Header,
    Input,
    Table,
    Textarea
} from "@amzn/awsui-components-react";
import { UIField } from "../../common/UIField";
import { AdvancedListContent, Identity } from "@amzn/ask-legal-domain";
import { AdvancedList, FieldDefinitionDataType } from "@amzn/altar-sds-client";
import { AdvancedListPolarisFactory } from "../../../factory/polaris/advanced-list-polaris-factory";
import { AppContext } from "../../../setup/context";
import { useAPI2 } from "../../../hooks/api-hook";
import { AddFieldDefinition } from "./AddFieldDefinition";

export const AdvancedListContentEdit = (props: {
    state: UIModel.State<AdvancedListContent>;
}) => {

    const [fieldDisplayName, setFieldDisplayName] = React.useState("");
    const context = React.useContext(AppContext);
    const loadAdvancedListRunner = useAPI2(
        context.getAdvancedListAPI().load
    );
    const [advancedList, setAdvancedList] = React.useState<AdvancedList>();
    const [userIdentity, setUserIdentity] = React.useState<Identity>(null);

    React.useEffect(() => {
        if (userIdentity?.id) {
            loadAdvancedListRunner.invoke({
                entityId: props.state?.value?.entityRef?.entityId,
                repositoryId: props.state?.value?.entityRef?.repositoryRef.repositoryId,
                by: {
                    id: userIdentity.id,
                    realm: "Amazon",
                    type: "Person"
                }
            });
        }
    }, [props.state?.value?.entityRef?.entityId, userIdentity]);

    const fetchIdentity = async () => {
        let currentUser: Identity;
        try {
            currentUser = await context.getIdentity();
        } catch (err) {
            console.warn(err);
        }
        if (!currentUser) {
            console.warn("Error fetching user");
            setUserIdentity(null);
        }
        setUserIdentity(currentUser);
    };

    React.useEffect(() => {
        if (loadAdvancedListRunner.output) {
            setAdvancedList(loadAdvancedListRunner.output.body);
        }
    }, [loadAdvancedListRunner.status]);

    React.useEffect(() => {
        fetchIdentity();
    }, []);

    return (
        <>
            <hr />
            <Header variant="h3">Advanced List Configuration</Header>
            <Grid
                gridDefinition={[
                    { colspan: 5 },
                    { colspan: 7 }
                ]}
            >
                <UIField.CustomField
                    name="Name"
                    child={<Input
                        value={props.state.value?.updateAdvancedListPayload?.name}
                        placeholder={advancedList?.name}
                        onChange={(e) => {
                            props.state.setValue(
                                {...props.state.value,
                                    updateAdvancedListPayload: {
                                        ...props.state.value.updateAdvancedListPayload,
                                        name: e.detail.value
                                    }
                                }
                            );
                        }}
                    />}
                />
                <UIField.CustomField
                    name="Description"
                    child={<Textarea
                        value={props.state.value?.updateAdvancedListPayload?.description}
                        placeholder={advancedList?.description}
                        onChange={(e) => {
                            props.state.setValue(
                                {...props.state.value,
                                    updateAdvancedListPayload: {
                                        ...props.state.value.updateAdvancedListPayload,
                                        description: e.detail.value
                                    }
                                }
                            );
                        }}
                    />}
                />
            </Grid>
            <hr />

            <br/>
            <h4>Live Advanced List Table Schema</h4>
            <Table
                items={advancedList?.fieldDefinitions}
                columnDefinitions={[
                    AdvancedListPolarisFactory.Table.DisplayName,
                    AdvancedListPolarisFactory.Table.DataType
                ]}
                loading={loadAdvancedListRunner.status === "Running"}
            />

            <br/>
            <h2>Draft Field Definitions</h2>
            <AddFieldDefinition state={props.state} />
            <Table
                items={props.state?.value?.updateAdvancedListPayload?.addFieldDefinitionsList}
                columnDefinitions={[
                    AdvancedListPolarisFactory.Table.DisplayName,
                    AdvancedListPolarisFactory.Table.DataType
                ]}
            />
        </>
    );
};