import {
    AmazonApprovalTemplatePayload,
    APIOutput,
    EntityRef,
    EntityType,
    ExternalReference,
    Filter,
    FilterField,
    GeneralEntity,
    ServerSidePaginatedLoadingInput,
    ServerSidePaginatedLoadingOutput
} from "@amzn/ask-legal-domain";
import { Multiselect, MultiselectProps, Select, SelectProps } from "@amzn/awsui-components-react";
import { useEffect } from "react";
import { useAPI } from "../../hooks/api-hook";
import * as React from "react";
import { Builder } from "builder-pattern";
import { DropdownStatusProps } from "@amzn/awsui-components-react/polaris/internal/components/dropdown-status";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import { APIResponse } from "../../api/common";
import { useComponnentProps } from "../../hooks/polaris-component-hooks";
import { UIConstants } from "./common-utils";

const DEFAULT_PAGE_SIZE = 20; // Loading more items to reduce number of API calls
export namespace EntitySelection {
    export const Single = <I, E extends GeneralEntity>(props: {
        api: (input: I) => Promise<APIResponse<E[]>>;
        input: I;
        selected: EntityRef;
        onSelectionChange: (entities: E) => void;
        filterOutFunction?: (o: OptionDefinition) => boolean;
    }) => {
        const apiRunner = useAPI(props.api);
        useEffect(() => {
            apiRunner.submitRun(props.input);
        }, [props.input]);

        const [entities, setEntities] = React.useState<E[]>([]);
        const [allOptions, setAllOptions] = React.useState<OptionDefinition[]>([]);
        const [displayedOptions, setDisplayedOptions] = React.useState<OptionDefinition[]>([]);
        const [statusType, setStatusType] = React.useState<string>("loading");
        const [filteringText, setFilteringText] = React.useState<string>("");

        const toOption = (e: E) => {
            if (e.type === EntityType.ExternalReference) {
                if ((e as any).referenceType === "AmazonApprovalTemplate") {
                    const templateId = (e as any as ExternalReference.Data<AmazonApprovalTemplatePayload>).payload.templateId;
                    return {
                        label: `${e.name} [${templateId}]`,
                        value: e.id
                    };
                }
            }
            return {
                label: e.name ? e.name : e.id,
                value: e.id
            };
        };

        const applyFilterOutFunction = () => {
            if (!props.filterOutFunction) return displayedOptions;
            return displayedOptions.filter(x => !props.filterOutFunction(x));
        };

        React.useEffect(() => {
            if (!filteringText || filteringText.length === 0) {
                setDisplayedOptions(allOptions);
            } else {
                setDisplayedOptions(allOptions.filter(o =>
                    o.label.toLowerCase().includes(filteringText.toLowerCase())
                ));
            }
        }, [filteringText, allOptions]);

        useEffect(() => {
            if (apiRunner.status === "Succeeded") {
                setStatusType("finished");
                setEntities(apiRunner.data.output);
            } else if (apiRunner.status === "Error") {
                setStatusType("error");
                setEntities([]);
            }
        }, [apiRunner.status]);

        useEffect(() => {
            if (entities.length > 0) {
                setAllOptions(entities.map(e => toOption(e)));
            }
        }, [entities]);

        const selectProps = Builder<SelectProps>()
            .loadingText((apiRunner.status !== "Succeeded" && apiRunner.status !== "Error" ) ? "Loading..." : undefined)
            .errorText(apiRunner.status === "Error" ? "Error fetching options." : undefined)
            .empty(filteringText.length > 0 ? "No match found" : "No availble options")
            .statusType(statusType as DropdownStatusProps.StatusType)
            .options(applyFilterOutFunction())
            .selectedOption(props.selected ? allOptions.find(o => props.selected.id === o.value) : undefined)
            .filteringType("manual")
            .filteringPlaceholder("Type to filter")
            .placeholder("Select entity from dropdown")
            .onChange(e => {
                const selectedEntity = entities.find(d =>
                    e.detail.selectedOption.value === d.id
                );
                props.onSelectionChange(selectedEntity);
            })
            .onLoadItems(e => setFilteringText(e.detail.filteringText))
            .build();

        return <Select {...selectProps}/>;
    };

    export const Multi = <I, E extends GeneralEntity>(props: {
        api: (input: I) => Promise<APIResponse<E[]>>;
        input: I;
        selected: EntityRef[];
        onSelectionChange: (entities: E[]) => void;
    }) => {
        const apiRunner = useAPI(props.api);
        useEffect(() => {
            apiRunner.submitRun(props.input);
        }, [props.input]);

        const [entities, setEntities] = React.useState<E[]>([]);
        const [allOptions, setAllOptions] = React.useState<OptionDefinition[]>([]);
        const [displayedOptions, setDisplayedOptions] = React.useState<OptionDefinition[]>([]);
        const [statusType, setStatusType] = React.useState<string>("loading");
        const [filteringText, setFilteringText] = React.useState<string>("");

        const toOption = (e: E) => {
            return {
                label: e.name ? e.name : e.id,
                value: e.id
            };
        };

        React.useEffect(() => {
            if (!filteringText || filteringText.length === 0) {
                setDisplayedOptions(allOptions);
            } else {
                setDisplayedOptions(allOptions.filter(o =>
                    o.label.toLowerCase().includes(filteringText.toLowerCase())
                ));
            }
        }, [filteringText, allOptions]);

        useEffect(() => {
            if (apiRunner.status === "Succeeded") {
                setStatusType("finished");
                setEntities(apiRunner.data.output);
            } else if (apiRunner.status === "Error") {
                setStatusType("error");
                setEntities([]);
            }
        }, [apiRunner.status]);

        useEffect(() => {
            if (entities.length > 0) {
                setAllOptions(entities.map(e => toOption(e)));
            }
        }, [entities]);

        const multiselectProps = Builder<MultiselectProps>()
            .loadingText((apiRunner.status !== "Succeeded" && apiRunner.status !== "Error" ) ? "Loading..." : undefined)
            .errorText(apiRunner.status === "Error" ? "Error fetching options." : undefined)
            .empty(filteringText.length > 0 ? "No match found" : "No availble options")
            .statusType(statusType as DropdownStatusProps.StatusType)
            .options(displayedOptions)
            .selectedOptions(allOptions.filter(o => props.selected.find(s => s.id === o.value)))
            .filteringType("manual")
            .filteringPlaceholder("Type to filter")
            .placeholder("Select entity from dropdown")
            .onChange(e => {
                const selectedEntities = entities.filter(d =>
                    e.detail.selectedOptions.find(o => o.value === d.id)
                );
                props.onSelectionChange(selectedEntities);
            })
            .onLoadItems(e => setFilteringText(e.detail.filteringText))
            .build();

        return <Multiselect {...multiselectProps}/>;
    };

    export const SingleWithPagination = <T extends GeneralEntity>(props: {
        api: (input: ServerSidePaginatedLoadingInput) => Promise<APIResponse<ServerSidePaginatedLoadingOutput<T>>>;
        partitionKey: string;
        selected: EntityRef;
        onSelectionChange: (entities: T) => void;
        pageSize?: number;
        defaultFilters?: Filter[];
        disabled?: boolean;
    }) => {
        // //page index starts from 1
        const [currentPageIndex, setCurrentPageIndex] = React.useState<number>(1);
        const [entities, setEntities] = React.useState<T[]>([]);
        const [filteringText, setFilteringText] = React.useState<string>("");

        const toOption2 = (ref: EntityRef) => {
            if (!ref) return null;
            return {
                label: ref.id,
                value: ref.id
            };
        };

        const toOption = (e: T) => ({
            label: e.name ? e.name : e.id,
            value: e.id
        });

        const selectProps = useComponnentProps<SelectProps>({
            loadingText: "Loading...",
            errorText: "Error fetching data...",
            finishedText: "No more options",
            filteringType: "manual",
            placeholder: "Select from dropdown",
            options: [],
            filteringPlaceholder: "Type to filter",
            statusType: "pending",
            selectedOption: toOption2(props.selected),
        });

        const handleLoadItems = e => {
            // filtering text changed
            if (e.detail.filteringText !== filteringText) {
                setFilteringText(e.detail.filteringText);
                setCurrentPageIndex(1);
                selectProps.setProps(prev => ({
                    ...prev,
                    options: [],
                    statusType: "loading"
                }));
            } else if (!e.detail.samePage) {
                selectProps.setProps(prev => ({
                    ...prev,
                    statusType: "loading"
                }));
                setCurrentPageIndex(currentPageIndex + 1);
            }
        };

        const handleOnChange = e => {
            const selectedEntity = entities.find(entity =>
                e.detail.selectedOption.value === entity.id
            );
            props.onSelectionChange(selectedEntity);
        };

        const loadItems = async () => {
            try {
                const pageSize = props.pageSize ? props.pageSize : DEFAULT_PAGE_SIZE;
                selectProps.setProps(prev => ({
                    ...prev,
                    statusType: "loading"
                }));
                const currentFilteringText = filteringText;
                let constructedFilters: Filter[] = [];
                if (props.defaultFilters) {
                    constructedFilters = props.defaultFilters;
                }
                if (!!filteringText && filteringText.length > 0) {
                    constructedFilters.push({
                        field: FilterField.Name,
                        value: filteringText,
                        negate: false,
                        operator: "must"
                    });
                }
                const rawOutput = await props.api(
                    ServerSidePaginatedLoadingInput.create({
                        partitionKey: props.partitionKey,
                        currentPageIndex: currentPageIndex,
                        pageSize: pageSize,
                        filters: constructedFilters
                    })
                );
                if (currentFilteringText !== filteringText) {
                    // filteringText was changed while the request is processing, ignore current one
                    return;
                }
                const output = APIOutput.fromRaw<ServerSidePaginatedLoadingOutput<T>>(rawOutput.data);
                if (output.isErr()) {
                    selectProps.setProps(prev => ({
                        ...prev,
                        errorText: output.err.message,
                        statusType: "error"
                    }));
                } else {
                    setEntities(prev => currentPageIndex === 1 ? output.data.result : prev.concat(output.data.result));
                    // if no more options available
                    let statusType: DropdownStatusProps.StatusType = "finished";
                    if (output.data.totalCount > pageSize * currentPageIndex) {
                        statusType = "pending";
                    }
                    selectProps.setProps(prev => ({
                        ...prev,
                        statusType: statusType
                    }));
                }
            } catch (e) {
                let message = UIConstants.ERROR_MESSAGE;
                if (!!e.response && !!e.response.data && !!e.response.data.message) {
                    message = e.response.data.message;
                }
                selectProps.setProps(prev => ({
                    ...prev,
                    errorText: message,
                    statusType: "error"
                }));
            }
        };

        useEffect(() => {
            let selectedOption = null;
            if (!!props.selected) {
                const found = entities.find(e => e.id === props.selected.id);
                if (!!found) {
                    selectedOption = toOption(found);
                } else {
                    selectedOption = toOption2(props.selected);
                }
            }
            const currentOptions = entities.map(e => toOption(e));
            currentPageIndex === 1 ? currentOptions : selectProps.props.options.concat(currentOptions),
            selectProps.setProps(prev => ({
                ...prev,
                options: currentOptions,
                selectedOption: selectedOption
            }));
        }, [props.selected, entities]);

        useEffect(() => {
            loadItems();
        }, [
            filteringText,
            currentPageIndex,
        ]);

        useEffect(() => {
            setEntities([]);
            setCurrentPageIndex(1);
            setFilteringText("");
            loadItems();
        }, [props.partitionKey]);

        return <Select
            {...selectProps.props}
            onLoadItems={handleLoadItems}
            onChange={handleOnChange}
            disabled={props.disabled}
        />;
    };

    export const MultiWithPagination = <T extends GeneralEntity>(props: {
        api: (input: ServerSidePaginatedLoadingInput) => Promise<APIResponse<ServerSidePaginatedLoadingOutput<T>>>;
        partitionKey: string;
        selected: EntityRef[];
        onSelectionChange: (entities: T[]) => void;
        pageSize?: number
    }) => {
        // //page index starts from 1
        const [currentPageIndex, setCurrentPageIndex] = React.useState<number>(1);
        const [entities, setEntities] = React.useState<T[]>([]);
        const [filteringText, setFilteringText] = React.useState<string>("");

        const toOption2 = (ref: EntityRef) => ({
            label: ref.id,
            value: ref.id
        });

        const toOption = (e: T) => ({
            label: e.name ? e.name : e.id,
            value: e.id
        });

        const multiselectProps = useComponnentProps<MultiselectProps>({
            loadingText: "Loading...",
            errorText: "Error fetching data...",
            finishedText: "No more options",
            filteringType: "manual",
            placeholder: "Select from dropdown",
            options: [],
            filteringPlaceholder: "Type to filter",
            statusType: "pending",
            tokenLimit: 10,
            keepOpen: true,
            selectedOptions: props.selected.map(ref => toOption2(ref)),
        });

        const handleLoadItems = e => {
            // filtering text changed
            if (e.detail.filteringText !== filteringText) {
                setFilteringText(e.detail.filteringText);
                setCurrentPageIndex(1);
                multiselectProps.setProps(prev => ({
                    ...prev,
                    options: [],
                    statusType: "loading"
                }));
            } else if (!e.detail.samePage) {
                multiselectProps.setProps(prev => ({
                    ...prev,
                    statusType: "loading"
                }));
                setCurrentPageIndex(currentPageIndex + 1);
            }
        };

        const handleOnChange = e => {
            const selectedEntities = entities.filter(d =>
                e.detail.selectedOptions.find(o => o.value === d.id)
            );
            props.onSelectionChange(selectedEntities);
        };

        const loadItems = async () => {
            try {
                const pageSize = props.pageSize ? props.pageSize : DEFAULT_PAGE_SIZE;
                multiselectProps.setProps(prev => ({
                    ...prev,
                    statusType: "loading"
                }));
                const currentFilteringText = filteringText;
                const rawOutput = await props.api(
                    ServerSidePaginatedLoadingInput.create({
                        partitionKey: props.partitionKey,
                        currentPageIndex: currentPageIndex,
                        pageSize: pageSize,
                        filters: (!!filteringText && filteringText.length > 0) ? [{
                            field: FilterField.Name,
                            value: filteringText,
                            negate: false,
                            operator: "must"
                        }] : []
                    })
                );
                if (currentFilteringText !== filteringText) {
                    // filteringText was changed while the request is processing, ignore current one
                    return;
                }
                const output = APIOutput.fromRaw<ServerSidePaginatedLoadingOutput<T>>(rawOutput.data);
                if (output.isErr()) {
                    multiselectProps.setProps(prev => ({
                        ...prev,
                        errorText: output.err.message,
                        statusType: "error"
                    }));
                } else {
                    setEntities(prev => currentPageIndex === 1 ? output.data.result : prev.concat(output.data.result));
                    // if no more options available
                    let statusType: DropdownStatusProps.StatusType = "finished";
                    if (output.data.totalCount > pageSize * currentPageIndex) {
                        statusType = "pending";
                    }
                    multiselectProps.setProps(prev => ({
                        ...prev,
                        statusType: statusType
                    }));
                }
            } catch (e) {
                let message = UIConstants.ERROR_MESSAGE;
                if (!!e.response && !!e.response.data && !!e.response.data.message) {
                    message = e.response.data.message;
                }
                multiselectProps.setProps(prev => ({
                    ...prev,
                    errorText: message,
                    statusType: "error"
                }));
            }
        };

        useEffect(() => {
            const selectedOptions = [];
            for (const select of props.selected) {
                const found = entities.find(e => e.id === select.id);
                if (!!found) {
                    selectedOptions.push(toOption(found));
                } else {
                    selectedOptions.push(toOption2(select));
                }
            }
            const currentOptions = entities.map(e => toOption(e));
            currentPageIndex === 1 ? currentOptions : multiselectProps.props.options.concat(currentOptions),
            multiselectProps.setProps(prev => ({
                ...prev,
                options: currentOptions,
                selectedOptions: selectedOptions
            }));
        }, [props.selected, entities]);

        useEffect(() => {
            loadItems();
        }, [
            filteringText,
            currentPageIndex,
        ]);

        useEffect(() => {
            setEntities([]);
            setCurrentPageIndex(1);
            setFilteringText("");
            loadItems();
        }, [props.partitionKey]);

        return <Multiselect
            {...multiselectProps.props}
            onLoadItems={handleLoadItems}
            onChange={handleOnChange}
        />;
    };
}