import {
    Badge,
    Box,
    Button,
    ColumnLayout,
    FormField,
    Input,
    SpaceBetween
} from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI2 } from "../../../hooks/api-hook";
import { AppContext } from "../../../setup/context";
import {
    DecisionTreeContent,
    DecisionTreeNode,
    EntityRef,
    IDecisionTree,
    PrimitiveDataFactory
} from "@amzn/ask-legal-domain";
import { UIModel } from "../../../model/ui-model";
import { UIField } from "../../common/UIField";
import { ErrorFlashbar } from "../../common/ErrorFlashbar";
import { Builder } from "builder-pattern";
import { LoadingSpinner } from "../../common/loading-spinner";
import { CustomCommonContainerModal } from "../../container/CommonContainerModal";
import { StrongRichTextEdit } from "../../common/RichTextEditor/TinyMCEBased/StrongRichTextEdit";

export const NodeEditModal = (props: {
    draftRef: EntityRef;
    nodeId: string;
    containerId: string;
    onDismiss: () => void;
    onSucceed: (updateContent: DecisionTreeContent) => void;
}) => {
    const context = React.useContext(AppContext);
    const getNodeRunner = useAPI2(context.getDecisionTreeNodeAPI().get);
    const updateNodeRunner = useAPI2(context.getDecisionTreeNodeAPI().update);
    const [loadedNode, setLoadedNode] = React.useState<DecisionTreeNode>(null);

    const overviewText = UIModel.State.useNotNullString({ initialValue: "" });
    const question = UIModel.State.useNotNullString({ initialValue: "" });

    const children = UIModel.State.useArray<{
        answer: string;
        nodeId: string;
        new: boolean; // to indicate this child is newly created or not
    }>({ initialValue: [] });

    const childrenToDelete = UIModel.State.useArray<{
        answer: string;
        nodeId: string;
    }>({ initialValue: [] });

    React.useEffect(() => {
        getNodeRunner.invoke({
            containerId: props.containerId,
            nodeId: props.nodeId
        });
    }, []);

    React.useEffect(() => {
        if (getNodeRunner.status === "Succeeded") {
            const nodeData = getNodeRunner.output;
            setLoadedNode(nodeData.metadata);
            question.setValue(nodeData.content.contentValue);
            overviewText.setValue(nodeData.metadata.overviewText);
            children.setValue(nodeData.metadata.children.map(child => ({
                answer: child.answer,
                nodeId: child.nodeId,
                new: false
            })));
        }
    }, [getNodeRunner.status]);

    const onSubmit = () => {
        const updateNodeInput = IDecisionTree.UpdateDraftInput.create({
            draftRef: props.draftRef,
            containerId: props.containerId,
            nodeId: props.nodeId,
            overviewText: overviewText.value,
            question: question.value,
            children: children.value
                .filter(child => childrenToDelete.value.every(c => c.nodeId !== child.nodeId))
                .map(child => ({
                    answer: child.answer,
                    nodeId: child.nodeId
                }))
        });
        updateNodeRunner.invoke(updateNodeInput);
    };

    React.useEffect(() => {
        if (updateNodeRunner.status === "Succeeded") {
            props.onSucceed(updateNodeRunner.output);
        }
    }, [updateNodeRunner.status]);

    return <>
        <CustomCommonContainerModal
            size="lg"
            header="Edit Node"
            onCancel={props.onDismiss}
            onSave={onSubmit}
            loading={updateNodeRunner.status === "Running"}
            disabled={
                getNodeRunner.status === "Running" ||
                children.value.some(x => x.answer.length === 0) ||
                !!overviewText.errorText ||
                !!question.errorText
            }
            child={<>
                {!!updateNodeRunner.err && <ErrorFlashbar error={updateNodeRunner.err}/>}
            {getNodeRunner.status === "Running" && <LoadingSpinner/>}
            {!!loadedNode && <ColumnLayout columns={1} borders="horizontal">
                <UIField.StateValueField
                    name="Node overview text"
                    state={overviewText}
                    editing={true}
                    variant="Input"
                    constraintText="Description text"
                />
                <UIField.CustomField
                    name={`Question`}
                    child={<StrongRichTextEdit
                        content={question.value}
                        onChange={updated => question.setValue(updated)}
                        hidePermContent={true}
                    />}
                    errorText={question.errorText}
                />
                <SpaceBetween direction="vertical" size="m">
                    <Button variant="primary" iconName="add-plus" onClick={() => {
                        children.setValue([
                            ...children.value,
                            {
                                answer: "Default Answer",
                                nodeId: PrimitiveDataFactory.id(),
                                new: true
                            }
                        ]);
                    }}>Add Answer</Button>
                    {children.value.map((child, index) =>
                        <FormField
                            errorText={child.answer.length === 0 && "Value cannot be empty"}
                            label={<Box margin={{ bottom: "xxxs" }} color="text-label">
                                Answer&nbsp;
                                { childrenToDelete.value.some(c => c.nodeId === child.nodeId) ?
                                    <span><Badge color="red">Deleted</Badge>&nbsp;All children nodes underneath will be deleted</span> :
                                    (child.new && <Badge color="green">New</Badge>)
                                }
                            </Box>}>
                                <SpaceBetween direction="horizontal" size="xs">
                                    <div style={{ width: "500px" }}>
                                        <Input
                                            value={child.answer}
                                            onChange={(e: { detail: { value: string; }; }) => {
                                                const updatedAnswer = Builder(child)
                                                    .answer(e.detail.value)
                                                    .build();
                                                const updatedAnswers = children.value.slice();
                                                updatedAnswers.splice(index, 1, updatedAnswer);
                                                children.setValue(updatedAnswers);
                                            }}
                                            disabled={childrenToDelete.value.some(c => c.nodeId === child.nodeId)}
                                        />
                                    </div>
                                    {childrenToDelete.value.every(c => c.nodeId !== child.nodeId) && <Button iconName="remove" onClick={() =>
                                        childrenToDelete.setValue([...childrenToDelete.value, child])
                                    }>Delete</Button>}
                                    {childrenToDelete.value.some(c => c.nodeId === child.nodeId) && <Button onClick={() =>
                                        childrenToDelete.setValue(
                                            childrenToDelete.value.filter(c => c.nodeId !== child.nodeId)
                                        )
                                    }>Recover</Button>}
                                </SpaceBetween>
                        </FormField>
                    )}
                </SpaceBetween>
            </ColumnLayout>}</>
            }
        />
    </>;
};