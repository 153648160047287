import {
    IExternalReference,
    AmazonApprovalTemplatePayload,
    ExternalReference,
    EntityType,
    EntityRef,
    EntityFactory,
    PageLibrary,
    PageLibraryInterface,
    AppError,
    APIOutput,
    ErrorCode,
    AMZN_APPROVALS_LINK,
    AMZN_APPROVALS_STAGE_LINK,
    AMZN_APPROVALS_REGEX,
    Stage
} from "@amzn/ask-legal-domain";
import {
    Box,
    Button,
    ColumnLayout,
    FormField,
    Grid,
    Link,
    Modal,
    SpaceBetween,
    Spinner,
    Toggle
} from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI2 } from "../../hooks/api-hook";
import { ExternalReferenceModel } from "../../model/external-reference-model";
import { UIModel } from "../../model/ui-model";
import { AppContext } from "../../setup/context";
import { Preference } from "../../setup/preference";
import { UIConstants, getApprovalBaseUrl } from "../common/common-utils";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { UIField } from "../common/UIField";

export namespace PageLibraryApprovalSetting {
    export const MainComp = (props: { pageLibraryId: string }) => {
        const loading = UIModel.State.use<boolean>({
            initialValue: false
        });
        const [showTemplateAttach, setShowTemplateAttach] = React.useState<boolean>(true);

        return (
            <div>
                <NoApproverCheckboxComp
                    pageLibraryId={props.pageLibraryId}
                    loading={loading}
                    onChange={(enableApprovalWorkflow: boolean) => setShowTemplateAttach(enableApprovalWorkflow)}
                />
                <br/>
                {showTemplateAttach && <TemplateAttachComp pageLibraryId={props.pageLibraryId} loading={loading}/>}
            </div>
        );
    };

    const TemplateAttachComp = (props: {
        pageLibraryId: string,
        loading: UIModel.State<boolean>,
    }) => {
        const context = React.useContext(AppContext);
        const appStage = context.getStage();
        const approvalBaseUrl = getApprovalBaseUrl(appStage);
        const [
            createExternalReferenceModalVisible,
            setCreateExternalReferenceModalVisible
        ] = React.useState<boolean>(false);

        const createExternalReferenceRunner = useAPI2<
            IExternalReference.CreateExternalReferenceInput<AmazonApprovalTemplatePayload>,
            ExternalReference.Data<AmazonApprovalTemplatePayload>
        >(context.getExternalReferenceAPI().create);

        const createExternalReferenceState = ExternalReferenceModel.CreateState.use<AmazonApprovalTemplatePayload>({
            baseEntityId: props.pageLibraryId,
            baseEntityType: EntityType.PageLibrary,
            referenceType: "AmazonApprovalTemplate",
            initialPayload: { templateId: "" }
        });
        const approvalURLState = UIModel.State.useWithRegexValidation({
            initialValue: "",
            regex: new RegExp(appStage === Stage.Prod ? AMZN_APPROVALS_REGEX.PROD : AMZN_APPROVALS_REGEX.STAGE)
        });

        const loadExternalReferenceRunner = useAPI2<
            EntityRef,
            ExternalReference.Data<AmazonApprovalTemplatePayload>[]
        >(context.getExternalReferenceAPI().loadByBaseEntity);

        const loadTemplates = () => {
            loadExternalReferenceRunner.invoke(
                EntityFactory.fromEntityAttributes(
                    props.pageLibraryId,
                    EntityType.PageLibrary
                )
            );
        };

        React.useEffect(() => {
            loadTemplates();
        }, [props.pageLibraryId]);

        const createExternalReference = () => {
            createExternalReferenceRunner.invoke(
                ExternalReferenceModel.CreateState.toInput(createExternalReferenceState)
            );
        };

        const onDismissHandler = () => {
            setCreateExternalReferenceModalVisible(false);
            createExternalReferenceState.reset();
            approvalURLState.reset();
        };

        React.useEffect(() => {
            if (!approvalURLState.errorText) {
                createExternalReferenceState.payload.setValue({
                    templateId: approvalURLState.value.split("/")[5]
                });
            }
        }, [approvalURLState.value]);

        React.useEffect(() => {
            if (createExternalReferenceRunner.status === "Succeeded") {
                setCreateExternalReferenceModalVisible(false);
                createExternalReferenceState.reset();
                approvalURLState.reset();
                loadTemplates();
            }
        }, [createExternalReferenceRunner.status]);

        const onDeleted = (id: string) => {
            loadTemplates();
        };

        return (
            <React.Fragment>
                {createExternalReferenceModalVisible &&
                    <Modal
                        visible={createExternalReferenceModalVisible}
                        header="Attach an Amazon Approval Template"
                        closeAriaLabel="CloseAmazonApprovalTemplateAttachForm"
                        footer={
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button variant="link" onClick={onDismissHandler}>
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={createExternalReference}
                                        disabled={
                                            createExternalReferenceState.errorText.length > 0 ||
                                            !createExternalReferenceState.payload.value.templateId
                                        }
                                        loading={createExternalReferenceRunner.status === "Running"}>
                                        Save
                                    </Button>
                                </SpaceBetween>
                            </Box>}
                        size="medium"
                        onDismiss={onDismissHandler}
                    >
                        {createExternalReferenceRunner.status === "Error" &&
                            <ErrorFlashbar error={createExternalReferenceRunner.err}/>
                        }
                        <ColumnLayout borders="horizontal" columns={1}>
                            <div>
                                <div>Instructions:</div>
                                <div>Step 1. <Link
                                    external
                                    externalIconAriaLabel="Opens in a new tab"
                                    href={`${approvalBaseUrl}template/create`}
                                >
                                    Create new template
                                </Link> or <Link
                                    external
                                    externalIconAriaLabel="Opens in a new tab"
                                    href={`${approvalBaseUrl}Templates/active`}
                                >
                                    find an active template
                                </Link></div>
                                <div>Step 2. Copy the full web page URL of the template to below form, as shown in example</div>
                                <div>Step 3. Give name and description for this approval template</div>
                                <div>Step 4. Hit save</div>
                            </div>
                            <SpaceBetween direction="vertical" size="l">
                                <UIField.StateValueField
                                    state={approvalURLState}
                                    name={`Approval Template URL ${appStage === Stage.Prod ? "" : "(Non-Prod)"}`}
                                    constraintText={
                                        appStage === Stage.Prod ?
                                            `Example: ${AMZN_APPROVALS_LINK}Template/Details/12345` :
                                            `Example: ${AMZN_APPROVALS_STAGE_LINK}Template/Details/12345`
                                    }
                                    editing={true}
                                />
                                <UIField.StateValueField
                                    state={createExternalReferenceState.name}
                                    name="Approval Template Name"
                                    constraintText={`Maximum ${ExternalReferenceModel.NAME_CHAR_LIMIT} characters`}
                                    editing={true}
                                />
                                <UIField.StateValueField
                                    state={createExternalReferenceState.description}
                                    name="Approval Template Description"
                                    variant="TextArea"
                                    constraintText={`Maximum ${ExternalReferenceModel.DESCRIPTION_CHAR_LIMIT} characters`}
                                    editing={true}
                                />
                            </SpaceBetween>
                        </ColumnLayout>
                    </Modal>
                }
                <SpaceBetween direction="vertical" size="m">
                    <Button
                        variant="primary"
                        onClick={() => setCreateExternalReferenceModalVisible(true)}
                        loading={props.loading.value}
                    >Attach Template</Button>
                    {loadExternalReferenceRunner.status === "Running" && <Box textAlign="center" margin={"xxl"}>
                        <Spinner variant="normal" size="large"/>
                    </Box>}
                    {loadExternalReferenceRunner.status === "Succeeded" &&
                        <ApprovalTemplateDisplayComp approvalTemplates={loadExternalReferenceRunner.output} onDelete={onDeleted} approvalBaseUrl={approvalBaseUrl}/>
                    }
                </SpaceBetween>
            </React.Fragment>
        );
    };

    const NoApproverCheckboxComp = (props: {
        pageLibraryId: string,
        loading: UIModel.State<boolean>,
        onChange: (noApproval: boolean) => void,
    }) => {
        const context = React.useContext(AppContext);
        const [loadedPageLibrary, setLoadedPageLibrary] = React.useState<PageLibrary>(null);
        const [error, setError] = React.useState<AppError>(null);
        const loadPageLibraryRunner = useAPI2(context.getPageLibraryAPI().load);

        const updateNoApprovalWorkflowSetting = async (value: boolean) => {
            try {
                props.loading.setValue(true);
                const api = context.getPageLibraryAPI().update;
                const rawResponse = await api(PageLibraryInterface.UpdatePageLibraryInput.create(
                    EntityFactory.toEntityRef(loadedPageLibrary),
                    {
                        enableApprovalWorkflow: value
                    }
                ));
                const output = APIOutput.fromRaw<PageLibrary>(rawResponse.data);
                if (output.isErr()) {
                    setError(output.err);
                } else {
                    setLoadedPageLibrary(output.data);
                }
            } catch (e) {
                let code = 500;
                let message = UIConstants.ERROR_MESSAGE;
                if (!!e.response && !!e.response.data && !!e.response.data.message) {
                    message = e.response.data.message;
                }
                if (!!e.response && !!e.response.status && e.response.status > 0) {
                    code = e.response.status;
                }
                setError(AppError.create(message, code as ErrorCode));
            } finally {
                props.loading.setValue(false);
            }
        };

        React.useEffect(() => {
            loadPageLibraryRunner.invoke(props.pageLibraryId);
        }, []);

        React.useEffect(() => {
            if (loadPageLibraryRunner.status === "Succeeded") {
                setLoadedPageLibrary(loadPageLibraryRunner.output);
            } else if (loadPageLibraryRunner.status === "Error") {
                setError(loadPageLibraryRunner.err);
            }
        }, [loadPageLibraryRunner.status]);

        React.useEffect(() => {
            if (!!loadedPageLibrary) props.onChange(loadedPageLibrary.enableApprovalWorkflow);
        }, [loadedPageLibrary]);

        return <FormField>
            {!!error && <ErrorFlashbar error={error}/>}
            {(loadPageLibraryRunner.status === "Running" || props.loading.value) && <Spinner/>}
            {(loadPageLibraryRunner.status === "Succeeded" && !props.loading.value) && <Toggle
                onChange={(e: { detail: { checked: boolean; }; }) => updateNoApprovalWorkflowSetting(e.detail.checked)}
                checked={!!loadedPageLibrary ? !!loadedPageLibrary.enableApprovalWorkflow : false }
                disabled={!loadedPageLibrary}
                description="Once checked, an editor needs to get approval in order to publish a draft to live. Note: you cannot disable the approval workflow if there are any pending approvals for this page library">
                Enable Approval Workflow
            </Toggle>}
        </FormField>;
    };

    const ApprovalTemplateDisplayComp = (props: {
        approvalTemplates: ExternalReference.Data<AmazonApprovalTemplatePayload>[];
        approvalBaseUrl: string;
        onDelete: (id: string) => void;
    }) => {
        const context = React.useContext(AppContext);
        const deleteTemplateRunner = useAPI2(context.getExternalReferenceAPI().remove);

        const deleteTemplate = (id: string) => {
            deleteTemplateRunner.invoke(id);
        };

        React.useEffect(() => {
            if (deleteTemplateRunner.status === "Succeeded") {
                props.onDelete(deleteTemplateRunner.input);
            }
        }, [deleteTemplateRunner.status]);

        return <Box margin="l">
            <Grid gridDefinition={[
                { colspan: 3 },
                { colspan: 3 },
                { colspan: 4 },
                { colspan: 2 }]}>
                <strong>Template Name</strong>
                <strong>Template Id</strong>
                <strong>Template Description</strong>
                <strong>Actions</strong>
            </Grid>
            {props.approvalTemplates.map(template => (
                <Grid gridDefinition={[
                    { colspan: 3 },
                    { colspan: 3 },
                    { colspan: 4 },
                    { colspan: 2 }]}>
                    <div>{template.name}</div>
                    <SpaceBetween direction="horizontal" size="xs">
                        <Link
                            external
                            externalIconAriaLabel="Opens in a new tab"
                            href={`${props.approvalBaseUrl}Template/Details/${template.payload.templateId}`}
                        >{template.payload.templateId}</Link>
                    </SpaceBetween>
                    <div>{template.description}</div>
                    <Button variant="normal" loading={deleteTemplateRunner.status === "Running"} onClick={() => deleteTemplate(template.id)}>Delete</Button>
                </Grid>)
            )}
        </Box>;
    };
}