import * as React from "react";
import { Header, Tabs } from "@amzn/awsui-components-react";
import { AppContext } from "../../setup/context";
import { Identity } from "@amzn/ask-legal-domain";
import { UserProfileHome } from "./UserProfileHome";
import { MyLegalContactsTable } from "../../components/legal-contact/MyLegalContactsTable";

export const UserProfilePortal = (props: {
    id: string;
}) => {
    const context = React.useContext(AppContext);
    const [userId, setUserId] = React.useState<Identity>(null);

    const fetchIdentity = async () => {
        let currentUser: Identity;
        try {
            currentUser = await context.getIdentity();
        } catch (err) {
            console.warn(err);
        }
        if (!currentUser) {
            console.warn("Error fetching user");
            setUserId(null);
        }
        setUserId(currentUser);
    };

    React.useEffect(() => {
        fetchIdentity();
    }, []);

    return (
        <React.Fragment>
            <Header id="pageHeader" variant="h1">My Profile</Header>
            {!!userId && <Tabs
                tabs={[
                    {
                        label: "My Favorites",
                        id: "myInfo",
                        content: <UserProfileHome
                            user={userId}
                            instanceId={props.id}
                        />
                    },
                    {
                        label: "My Legal Contacts",
                        id: "myLegalContacts",
                        content: <MyLegalContactsTable instanceId={props.id} />
                    }
                ]}
            />}
        </React.Fragment>
    );
};