import { Document, DocumentFactory, EntityType, IFile, PageFactory, S3File } from "@amzn/ask-legal-domain";
import { Popover, Spinner, StatusIndicator } from "@amzn/awsui-components-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
const COPY_LINK = require("../../assets/copy-link.png").default;
const COPY_LINK_DARK = require("../../assets/copy-link-dark.png").default;
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import "../../styles/util-styles.scss";
import { BootstrapComp } from "../common/BootstrapComp";

export const ContainerLinkCopyButton = (props: {
    containerId: string
}) => {
    return (<span onClick={() => navigator.clipboard.writeText(
        `${window.location.origin}/#/page/${PageFactory.fromEntityId(props.containerId)}/live?container=${props.containerId}`
    )}>
        <Popover
            size="small"
            position="top"
            triggerType="custom"
            dismissButton={false}
            content={<StatusIndicator type="success">Container Link Copied</StatusIndicator>}>
            <span title="Copy container link" style={{ cursor: "pointer" }}>
                <img height={"30px"} src={COPY_LINK} />
            </span>
        </Popover ></span >);
};

export const DocumentLinkCopyButton = (props: {
    document: Document;
}) => {
    return <div onClick={() => {
        navigator.clipboard.writeText(
            `${window.location.origin}/#/page/${S3File.AskLegalFileKey.toEntityIdFromKey(props.document.s3File.key, EntityType.Page)
            }/live?container=${S3File.AskLegalFileKey.toEntityIdFromKey(props.document.s3File.key, EntityType.Container)
            }&document=${DocumentFactory.encodeDocumentName(props.document.filename)}`
        );
    }}>
        <Popover
            size="small"
            position="top"
            triggerType="custom"
            dismissButton={false}
            content={<StatusIndicator type="success">Document Link Copied</StatusIndicator>}>
            <span title="Copy document link" style={{ cursor: "pointer" }}>
                <img height={"30px"} src={COPY_LINK_DARK} />
            </span>
        </Popover>
    </div>;
};

export const DocumentPreviewButton = (props: {
    document: Document;
}) => {

    const context = React.useContext(AppContext);
    const getObjectPresignedUrlForPreviewRunner = useAPI(
        context.getFileAPI().getPresignedUrl
    );
    const [previewInProgress, setPreviewInProgress] = React.useState<boolean>(false);
    const [previewURL, setPreviewURL] = React.useState<string>();
    const [previewURLForIframe, setPreviewURLForIframe] = React.useState<string>();
    const [previewModalVisible, setPreviewModalVisible] = React.useState<boolean>(false);

    const fetchPreSignedPreviewURL = () => {
        setPreviewInProgress(true);
        getObjectPresignedUrlForPreviewRunner.submitRun(
            IFile.PresignedUrlInput.create({
                key: props.document.s3File.key,
                operation: IFile.PresignedOperation.Get,
                versionId: props.document.s3File.versionId,
                preview: true
            })
        );
    };

    React.useEffect(() => {
        if (getObjectPresignedUrlForPreviewRunner.status === "Running")
            return;
        if (getObjectPresignedUrlForPreviewRunner.status === "Succeeded") {
            setPreviewURL(getObjectPresignedUrlForPreviewRunner.data.output.presignedUrl);
        }
        setPreviewInProgress(false);
    }, [getObjectPresignedUrlForPreviewRunner.status]);

    React.useEffect(() => {
        if (!previewURL) {
            return;
        }
        setPreviewModalVisible(true);
    }, [previewURL]);

    React.useEffect(() => {
        if (previewModalVisible) {
            setPreviewURLForIframe(previewURL);
            getObjectPresignedUrlForPreviewRunner.resetStatus();
            setPreviewURL(undefined);
        }
    }, [previewModalVisible]);

    return <div >
        <div title="Preview document" style={{ cursor: "pointer" }} onClick={fetchPreSignedPreviewURL}>
            {previewInProgress ? <Spinner></Spinner> : <FontAwesomeIcon icon={faSearch} size="lg" />}
        </div>
        {(previewModalVisible) && (<BootstrapComp.CustomModal
            size="xl"
            visible={previewModalVisible} onDismiss={() => {
                setPreviewModalVisible(false);
            }}
            body={<iframe width={"100%"} height={"900px"} src={previewURLForIframe} ></iframe>}
            header={props.document.filename}
        />
        )}
    </div>;
};