import * as React from "react";
import { UIModel } from "../../../model/ui-model";
import {
    Button,
    Input,
    Select,
} from "@amzn/awsui-components-react";
import { UIField } from "../../common/UIField";
import { AdvancedListContent } from "@amzn/ask-legal-domain";
import { FieldDefinitionDataType } from "@amzn/altar-sds-client";

export const AddFieldDefinition = (props: {
    state: UIModel.State<AdvancedListContent>;
}) => {
    const [fieldDisplayName, setFieldDisplayName] = React.useState("");
    const [fieldDataType, setFieldDataType] = React.useState<FieldDefinitionDataType>();
    return (
        <>
            <UIField.CustomField
                name={"Column Display Name"}
                child={<Input
                    value={fieldDisplayName}
                    onChange={(e) => setFieldDisplayName(e.detail.value)}
                />}
            />
            <UIField.CustomField
                name={"Column Data Type"}
                child={<Select
                    options={Object.values(FieldDefinitionDataType).map(o => ({value: o}))}
                    selectedOption={{value: fieldDataType}}
                    onChange={
                        (e) => setFieldDataType(e.detail.selectedOption.value as FieldDefinitionDataType)
                    }
                />}
            />

            <Button
                onClick={() => {
                    props.state.setValue({
                        ...props.state.value,
                        updateAdvancedListPayload: {
                            ...props.state.value.updateAdvancedListPayload,
                            addFieldDefinitionsList: [
                                ...props.state?.value?.updateAdvancedListPayload?.addFieldDefinitionsList || [],
                                {
                                    displayName: fieldDisplayName,
                                    dataType: fieldDataType
                                }
                            ]
                        }
                    });
                    setFieldDisplayName("");
                    setFieldDataType(null);
                }}
            >Add</Button>
        </>
    );
};