import { Container } from "@amzn/ask-legal-domain";
import { ColumnLayout, SpaceBetween } from "@amzn/awsui-components-react";
import * as React from"react";
import { DateTimeFormatter } from "../common/DateTimeFormatter";
import { IdentityLink } from "../common/IdentityLink";
import { UIField } from "../common/UIField";

export const ContainerGeneralInfo = (props: {
    data: Container.Data;
}) => {
    return (
        <React.Fragment>
            <ColumnLayout columns={1} borders="horizontal">
                <ColumnLayout columns={2}>
                    <UIField.LabelField
                        label={"Container ID"}>
                        {props.data.id}
                    </UIField.LabelField>
                    <UIField.LabelField
                        label={"Container Type"}>
                        {props.data.containerType}
                    </UIField.LabelField>
                    <UIField.LabelField
                        label={"Current Version"}>
                        {props.data.version}
                    </UIField.LabelField>
                </ColumnLayout>
                <ColumnLayout columns={2} variant="text-grid">
                    <SpaceBetween size="s">
                        <UIField.LabelField
                            label={"Created By"}>
                            <IdentityLink
                                id={props.data.createdBy.id}
                                name={props.data.createdBy.name}
                            />
                        </UIField.LabelField>
                        <UIField.LabelField
                            label={"Last Modified By"}>
                            <IdentityLink
                                id={props.data.lastModifiedBy.id}
                                name={props.data.lastModifiedBy.name}
                            />
                        </UIField.LabelField>
                    </SpaceBetween>
                    <SpaceBetween size="s">
                        <UIField.LabelField
                            label={"Creation Date"}>
                            {DateTimeFormatter.formatDate(props.data.creationDate)}
                        </UIField.LabelField>
                        <UIField.LabelField
                            label={"Last Modified Date"}>
                            {DateTimeFormatter.formatDate(props.data.lastModifiedDate)}
                        </UIField.LabelField>
                    </SpaceBetween>
                </ColumnLayout>
                <ColumnLayout columns={1}>
                    <UIField.LabelField
                        label="Container Description"
                    >
                        {props.data.description}
                    </UIField.LabelField>
                </ColumnLayout>
            </ColumnLayout>
        </React.Fragment>
    );
};