import {
  CreateAdvancedListItemCommandInput,
  CreateAdvancedListItemCommandOutput,
  LoadAdvancedListCommandInput,
  LoadAdvancedListCommandOutput,
  UpdateAdvancedListItemCommandInput,
  UpdateAdvancedListItemCommandOutput,
  SearchAdvancedListItemsCommandInput,
  SearchAdvancedListItemsCommandOutput
} from "@amzn/altar-sds-client";
import { RestAPI } from "@aws-amplify/api-rest";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface AdvancedListAPI {
  load(input: LoadAdvancedListCommandInput): Promise<APIResponse<LoadAdvancedListCommandOutput>>;
  createItem(input: CreateAdvancedListItemCommandInput): Promise<APIResponse<CreateAdvancedListItemCommandOutput>>;
  updateItem(input: UpdateAdvancedListItemCommandInput): Promise<APIResponse<UpdateAdvancedListItemCommandOutput>>;
  searchItems(input: SearchAdvancedListItemsCommandInput): Promise<APIResponse<SearchAdvancedListItemsCommandOutput>>;
}

export class AdvancedListAPIImpl implements AdvancedListAPI {
  constructor(private context: AppContextValue) {}

  async load(input: LoadAdvancedListCommandInput): Promise<APIResponse<LoadAdvancedListCommandOutput>> {
    const response: APIResponse<LoadAdvancedListCommandOutput> = await RestAPI.post(
      "custom-domain",
      "/advanced-list/load",
      toDefaultRequestPayload(input)
    );
    return response;
  }

  async createItem(
    input: CreateAdvancedListItemCommandInput
  ): Promise<APIResponse<CreateAdvancedListItemCommandOutput>> {
    const response: APIResponse<CreateAdvancedListItemCommandOutput> = await RestAPI.post(
      "custom-domain",
      "/advanced-list/createItem",
      toDefaultRequestPayload(input)
    );
    return response;
  }

  async updateItem(
    input: UpdateAdvancedListItemCommandInput
  ): Promise<APIResponse<UpdateAdvancedListItemCommandOutput>> {
    const response: APIResponse<UpdateAdvancedListItemCommandOutput> = await RestAPI.post(
      "custom-domain",
      "/advanced-list/updateItem",
      toDefaultRequestPayload(input)
    );
    return response;
  }

  async searchItems(
    input: SearchAdvancedListItemsCommandInput
  ): Promise<APIResponse<SearchAdvancedListItemsCommandOutput>> {
    const response: APIResponse<SearchAdvancedListItemsCommandOutput> = await RestAPI.post(
      "custom-domain",
      "/advanced-list/searchItems",
      toDefaultRequestPayload(input)
    );
    return response;
  }
}
