import {
    DateTimeUtils,
    EntityFactory,
    EntityType,
    InstanceFactory,
    IPage,
    Label,
    Page,
    PageFactory,
    PageLibraryFactory,
    ServerSidePaginatedLoadingInput
} from "@amzn/ask-legal-domain";
import {
    Badge,
    Box,
    Button,
    ButtonDropdown,
    ButtonDropdownProps,
    ColumnLayout,
    Container,
    Flashbar,
    FlashbarProps,
    Form,
    Grid,
    Header,
    SpaceBetween,
    Spinner,
    StatusIndicator,
    TextContent,
    TokenGroup
} from "@amzn/awsui-components-react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { EntityPermissionPolarisFactory } from "../../factory/polaris/entity-permission-polaris-factory";
import { useAPI, useAPI2 } from "../../hooks/api-hook";
import { PageModel } from "../../model/page-model";
import { UIModel } from "../../model/ui-model";
import { AppContext } from "../../setup/context";
import { DeprecationModal } from "../common/DeprecationModal";
import { DeleteModal } from "../common/DeleteModal";
import { UIField } from "../common/UIField";
import { UserSearch } from "../common/UserSearch";
import { LabelAssignModal } from "../label/LabelAssignModal";
import { DeepCopyPageModal } from "./modal-content/DeepCopyPageModal";
import { SharePageModal } from "./modal-content/SharePageModal";

export const PageOverviewTab = (
    props: {
        state: UIModel.State<Page.Data>;
        setFlashbarItems: any;
        reload: () => void;
    }
) => {
    const history = useHistory();
    const context = React.useContext(AppContext);
    const checkFreshnessRunner = useAPI2(
        context.getPageAPI().checkFreshness
    );
    const updatePageMetadataRunner = useAPI(
        context.getPageAPI().updateMetadata
    );
    const updatePageMetadataState = PageModel.UpdateMetadataState.use({
        template: props.state.value
    });
    const [editing, setEditing] = React.useState<boolean>(false);
    const [modalType, setModalType] = React.useState<string>();
    const [pageFreshnessStatus, setPageFreshnessStatus] = React.useState<Page.Freshness>();
    const [timeToExpire, setTimeToExpire] = React.useState<number>();
    const [displayUpdates, setDisplayUpdates] = React.useState<Array<FlashbarProps.MessageDefinition>>([]);

    const freshPageStatus = <b><span style={{color: "green"}}>Fresh</span></b>;
    const expiredPageStatus = <b><span style={{color: "red"}}>Expired</span></b>;

    const loadLabelsRunner = useAPI2(context.getLabelAPI().getLabelsByResource);
    const loadPageLibraryRunner = useAPI(context.getPageLibraryAPI().load);

    const updatePage = () => {
        updatePageMetadataRunner.submitRun(
            PageModel.UpdateMetadataState.toInput(updatePageMetadataState)
        );
    };

    const getPageExpiryTimeinDays = (pageLibraryFreshness: number, pageFreshnessDate: Date) => {
        if (!pageLibraryFreshness || pageLibraryFreshness <= 0 || !pageFreshnessDate) {
            return -1;
        }
        const now = new Date();
        return pageLibraryFreshness - DateTimeUtils.getDatesDiffinDays(now, new Date(pageFreshnessDate));
    };

    const baselineFreshness = () => {
        updatePageMetadataRunner.submitRun(
            IPage.UpdatePageMetadataInput.create({
                baselineFreshness: true,
                targetRef: EntityFactory.toEntityRef(props.state.value)
            })
        );
    };

    const checkFreshness = () => {
        checkFreshnessRunner.invoke(props.state.value.id);
    };

    const flashbarMessage = {
        type: "warning",
        dismissible: false,
        header: "This page freshness is expired!",
        content: "Please baseline or publish a new version to set the page to fresh.",
        action: <Button onClick={baselineFreshness}>Baseline Page</Button>
    };

    const labelState = UIModel.State.useArray<Label.Data>({
        initialValue: []
    });

    const getButtonDropdownItemGroups = (): ButtonDropdownProps.Items => {
        const maintenanceItems: ButtonDropdownProps.Items = [
            { id: "activate", text: "Activate page", disabled: !props.state.value.deprecated },
            { id: "baselineFreshness", text: "Baseline page", disabled: props.state.value.deprecated },
            { id: "deprecate", text: "Deprecate page", disabled: props.state.value.deprecated },
            { id: "edit", text: "Edit page owner", disabled: false },
            { id: "manageLabels", text: "Manage labels", disabled: false }
        ];
        let administrationItems: ButtonDropdownProps.Items = [
            { id: "deepCopy", text: "Copy page", disabled: props.state.value.deprecated, iconName: "copy" },
            { id: "delete", text: "Delete page", disabled: false, iconName: "remove" },
            { id: "checkFreshness", text: "Send freshness reminder email", disabled: props.state.value.deprecated, iconName: "envelope" },
            { id: "share", text: "Share with other instances", disabled: props.state.value.deprecated, iconName: "share" }
        ];
        return [
            { text: "Maintenance", items: maintenanceItems },
            { text: "Administration", items: administrationItems }
        ];
    };

    const pageActionsClickHandler = (event: { detail: { id: string } }) => {
        switch (event.detail.id) {
            case "edit":
                updatePageMetadataState.reset();
                setEditing(true);
                break;
            case "checkFreshness":
                checkFreshness();
                break;
            case "baselineFreshness":
                baselineFreshness();
                break;
            case "deprecate":
            case "activate":
            case "delete":
            case "manageLabels":
            case "deepCopy":
            case "share":
                setModalType(event.detail.id);
                break;
            default: break;
        }
    };

    React.useEffect(() => {
        loadPageLibraryRunner.submitRun(props.state.value.pageLibraryId);
        loadLabelsRunner.invoke(ServerSidePaginatedLoadingInput.create({
            partitionKey: props.state.value.id,
            pageSize: 100,
            currentPageIndex: 1,
            filters: []
        }));
    }, []);

    React.useEffect(() => {
        // Update freshness whenever any page update occur
        loadPageLibraryRunner.reload();
        setPageFreshnessStatus(undefined);
        setTimeToExpire(undefined);
    }, [props.state.value]);

    React.useEffect(() => {
        if (loadLabelsRunner.status === "Succeeded") {
            labelState.setValue(loadLabelsRunner.output.result);
        }
    }, [loadLabelsRunner.status]);

    React.useEffect(() => {
        if (checkFreshnessRunner.status === "Succeeded") {
            setEditing(false);
            setDisplayUpdates([{
                type: "success",
                content: checkFreshnessRunner.output ?
                    `Page is in expired status, and email notification sent to Page owner ${props.state.value.pageOwner.id}` :
                    `Page is up-to-date, no email has been sent`,
                dismissible: true,
                onDismiss: () => setDisplayUpdates([])
            }]);
        } else if (checkFreshnessRunner.status === "Error") {
            setDisplayUpdates([{
                type: "error",
                content: `Failed checking page freshness or sending email. Reason: ${checkFreshnessRunner.err.message}`,
                dismissible: true,
                onDismiss: () => setDisplayUpdates([])
            }]);
        } else if (checkFreshnessRunner.status === "Running") {
            setDisplayUpdates([{
                type: "success",
                content: "Checking and sending email....",
                loading: true
            }]);
        }
    }, [checkFreshnessRunner.status]);

    React.useEffect(() => {
        if (updatePageMetadataRunner.status === "Succeeded") {
            setEditing(false);
            props.state.setValue(updatePageMetadataRunner.data.output);
            setDisplayUpdates([{
                type: "success",
                content: updatePageMetadataRunner.data.input.baselineFreshness ?
                    `Page freshness successfully baselined` : `Page details updated successfully`,
                dismissible: true,
                onDismiss: () => setDisplayUpdates([])
            }]);
        } else if (updatePageMetadataRunner.status === "Error") {
            updatePageMetadataState.reset();
            setDisplayUpdates([{
                type: "error",
                content: `${updatePageMetadataRunner.data.input.baselineFreshness ?
                    `Failed to baseline freshness.` :
                    `Failed to update page details.`} Reason: ${updatePageMetadataRunner.data.err.message}`,
                dismissible: true,
                onDismiss: () => setDisplayUpdates([])
            }]);
        } else if (updatePageMetadataRunner.status === "Running") {
            setDisplayUpdates([{
                type: "success",
                content: updatePageMetadataRunner.data.input.baselineFreshness ?
                    `Baselining page freshness...` : `Updating page details...`,
                loading: true
            }]);
        }
    }, [updatePageMetadataRunner.status]);

    React.useEffect(() => {
        if (loadPageLibraryRunner.status === "Succeeded") {
            const status = PageFactory.getPageFreshnessStatus(props.state.value, loadPageLibraryRunner.data.output.freshness);
            if (status === Page.Freshness.EXPIRED) {
                props.setFlashbarItems([flashbarMessage]);
            } else {
                props.setFlashbarItems([]);
            }
            setPageFreshnessStatus(status);
            setTimeToExpire(getPageExpiryTimeinDays(loadPageLibraryRunner.data.output.freshness, props.state.value.freshnessDate));
        } else if (loadPageLibraryRunner.status === "Error") {
            setDisplayUpdates([
                {
                    type: "error",
                    content: `Failed to fetch freshness status. Reason: ${loadPageLibraryRunner.data.err.message}`,
                    dismissible: true,
                    onDismiss: () => setDisplayUpdates([])
                },
                ...displayUpdates
            ]);
        }
    }, [loadPageLibraryRunner.status]);

    const renderModalContent = () => {
        switch (modalType) {
            case "activate":
            case "deprecate":
                return <DeprecationModal
                    entityRef={props.state.value}
                    deprecated={props.state.value.deprecated}
                    forwardingUrl={props.state.value.forwardingUrl}
                    onCancel={() => setModalType(null)}
                    onUpdated={(entity: Page.Data) => {
                        setModalType(null);
                        props.state.setValue(entity);
                    }}
                />;
            case "delete":
                return <DeleteModal
                    entityRef={props.state.value}
                    onCancel={() => setModalType(null)}
                    onDeleted={() =>
                        history.push(`/page-library/${PageLibraryFactory.fromEntityId(props.state.value.id)}/admin`)
                    }
                    customHeader="Delete Page"
                    customMessage={<strong><em>
                        This operation will permanently delete all resources on this page including permissions and page drafts.
                    </em></strong>}
                />;
            case "deepCopy":
                return <DeepCopyPageModal
                    onDismiss={() => {
                        setModalType(null);
                        props.reload();
                    }}
                    pageId={props.state.value.id}
                />;
            case "manageLabels":
                return <LabelAssignModal
                    resourceId={props.state.value.id}
                    resourceType={EntityType.Page}
                    instanceId={InstanceFactory.fromEntityId(props.state.value.id)}
                    onCloseModal={() => setModalType(null)}
                    onLinkSucceeded={newLabel => labelState.setValue([...labelState.value, newLabel])}
                    onUnlinkSucceeded={deletedId => {
                        const toDeleteIndex = labelState.value.findIndex(l => l.id === deletedId);
                        if (toDeleteIndex >= 0) {
                            const temp = labelState.value.slice();
                            temp.splice(toDeleteIndex, 1);
                            labelState.setValue(temp);
                        }
                    }}
                />;
            case "share":
                return <SharePageModal
                    pageId={props.state.value.id}
                    onDismiss={() => setModalType(null)}
                />;
            default:
                return <StatusIndicator type="error">Not yet implemented</StatusIndicator>;
        }
    };

    return (
        <React.Fragment>
            <SpaceBetween size="m" direction="vertical">
                <Flashbar
                    items={displayUpdates}
                />
                {modalType && renderModalContent()}
                <Container
                    header={
                        <Header
                            variant="h3"
                            actions={!editing &&
                                <SpaceBetween direction="horizontal" size="m">
                                    <ButtonDropdown
                                        items={getButtonDropdownItemGroups()}
                                        onItemClick={pageActionsClickHandler}
                                    >
                                        Page Actions
                                    </ButtonDropdown>
                                </SpaceBetween>
                            }>
                                <SpaceBetween size="s" direction="horizontal">
                                    Page Details
                                    {!props.state.value.deprecated && <StatusIndicator type="success">Active</StatusIndicator>}
                                    {props.state.value.deprecated && <StatusIndicator type="stopped">Deprecated</StatusIndicator>}
                                </SpaceBetween>
                        </Header>}>
                    <Form
                        actions={editing && (
                            <div>
                                <Button onClick={() => {
                                    updatePageMetadataState.reset();
                                    setEditing(false);
                                }} variant="link">Cancel</Button>
                                <Button
                                    variant="primary"
                                    onClick={updatePage}
                                    disabled={
                                        !updatePageMetadataState.isDirty() ||
                                        updatePageMetadataState.pageOwner.errorText ||
                                        updatePageMetadataState.forwardingUrl.errorText
                                    }
                                    loading={updatePageMetadataRunner.status === "Running"}
                                >Submit</Button>
                            </div>
                        )}>
                        <ColumnLayout columns={2}>
                            <SpaceBetween direction="vertical" size="xl">
                                <UIField.ValueField
                                    name="Name (part of the URL)"
                                    value={props.state.value.name}
                                />
                                <UIField.LabelField
                                    label="Page Owner"
                                    children={
                                        !editing || updatePageMetadataRunner.status === "Running" ?
                                            <TokenGroup
                                                items={!!updatePageMetadataState.pageOwner.value &&
                                                    EntityPermissionPolarisFactory.TokenGroup.toUserTokens(
                                                        [updatePageMetadataState.pageOwner.value])}
                                                onDismiss={({ detail: { itemIndex }}) => {
                                                    setEditing(true);
                                                }}
                                            /> :
                                            <Box padding={{ top: "xxxs" }}>
                                                <Grid gridDefinition={[{
                                                    colspan: 10
                                                }]}>
                                                    <UserSearch.Single
                                                        selected={updatePageMetadataState.pageOwner.value}
                                                        onUserSelectChange={(selected) => updatePageMetadataState.pageOwner.setValue(selected)}
                                                    />
                                                </Grid>
                                            </Box>}
                                />
                                {props.state.value.deprecated && !!props.state.value.forwardingUrl &&
                                    <UIField.StateValueField
                                        name="Deprecated forwarding URL"
                                        placeholder="Deprecated forwarding URL (optional)"
                                        state={updatePageMetadataState.forwardingUrl}
                                        editing={editing && updatePageMetadataRunner.status !== "Running"}
                                    />
                                }
                                <UIField.LabelField
                                    label="Labels"
                                    children={labelState.value.map(label => <Badge>{label.name}</Badge>)}
                                />
                            </SpaceBetween>
                            <SpaceBetween direction="vertical" size="l">
                                <UIField.ValueField
                                    name="Title"
                                    value={props.state.value.title}
                                />
                                <UIField.ValueField
                                    name="Description"
                                    value={props.state.value.description}
                                />
                                {!pageFreshnessStatus && loadPageLibraryRunner.status !== "Error" && <Spinner variant="normal"/>}
                                {!pageFreshnessStatus && loadPageLibraryRunner.status === "Error" && <StatusIndicator type="error">Failed</StatusIndicator>}
                                {!!pageFreshnessStatus && pageFreshnessStatus === "Expired" &&
                                    <UIField.LabelField label="Freshness Status">
                                        <TextContent>
                                            {expiredPageStatus}
                                        </TextContent>
                                    </UIField.LabelField>
                                }
                                {!!pageFreshnessStatus && pageFreshnessStatus === "Fresh" &&
                                    <UIField.LabelField label="Freshness Status (Expires in)">
                                        <TextContent>
                                            {freshPageStatus} {!timeToExpire && timeToExpire !== 0 && <Spinner variant="disabled" />}{
                                            !!timeToExpire && timeToExpire < 0 ? <small> N/A</small> : <small> {timeToExpire} days</small>}
                                        </TextContent>
                                    </UIField.LabelField>
                                }
                            </SpaceBetween>
                        </ColumnLayout>
                    </Form>
                </Container>
            </SpaceBetween>
        </React.Fragment>
    );
};