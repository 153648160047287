export namespace DateTimeFormatter {
    const monthMapping: Record<number, string[]> = {
        1: ["Jan", "January"],
        2: ["Feb", "February"],
        3: ["Mar", "March"],
        4: ["Apr", "April"],
        5: ["May", "May"],
        6: ["Jun", "June"],
        7: ["Jul", "July"],
        8: ["Aug", "August"],
        9: ["Sep", "September"],
        10: ["Oct", "October"],
        11: ["Nov", "November"],
        12: ["Dec", "December"]
    };

    export function formatDate(date: Date) {
        if (!date) return "";
        const d = new Date(date);
        return d.toLocaleDateString() + ", " + d.toLocaleTimeString();
    }

    export function toSimpleDate(date: string | Date, mode?: number) {
        if (!date) return "";
        if (!mode) mode = 0;
        const now = new Date();
        const d = new Date(date);
        return d.getDate() + " " +
            monthMapping[d.getMonth() + 1][mode] +
            (d.getFullYear() === now.getFullYear() && mode === 0 ? "" : ", " + d.getFullYear());
    }
}