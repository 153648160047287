import { AppError, APIOutput, ErrorCode } from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import * as React from "react";
import { APIResponse } from "../api/common";
import { UIConstants } from "../components/common/common-utils";
const isEqual = require("react-fast-compare");

export type ApiRunnerStatus = "New" | "Running" | "Error" | "Succeeded";

export function useAPI<I, O> (
    api: (input: I) => Promise<APIResponse<O>>,
) {
    type Data = { input?: I; output?: O; err?: AppError };

    const [status, setStatus] = React.useState<ApiRunnerStatus>("New");
    const [data, setData] = React.useState<Data>(
        Builder<Data>().build()
    );

    function invoke () {
        let status: ApiRunnerStatus = "Running";
        setStatus(status);
        api(data.input)
            .then((raw) => {
                const output = APIOutput.fromRaw<O>(raw.data);
                if (output.isErr()) {
                    setData((prev) => {
                        if (!isEqual(prev.input, data.input)) {
                            return prev;
                        }
                        return Builder(prev).err(output.err).build();
                    });
                    status = "Error";
                } else {
                    setData((prev) => {
                        if (!isEqual(prev.input, data.input)) {
                            return prev;
                        }
                        return Builder(prev).output(output.data).build();
                    });
                    status = "Succeeded";
                }
            })
            .catch((e) => {
                setData((prev) => {
                    if (!isEqual(prev.input, data.input)) {
                        return prev;
                    }
                    let code = 500;
                    let message = UIConstants.ERROR_MESSAGE;
                    if (!!e.response && !!e.response.data && !!e.response.data.message) {
                        message = e.response.data.message;
                    }
                    if (!!e.response && !!e.response.status && e.response.status > 0) {
                        code = e.response.status;
                    }
                    return Builder(prev).err(
                        AppError.create(message, code as ErrorCode)
                    ).build();
                });
                status = "Error";
            })
            .finally(() => {
                setStatus(status);
            });
    }

    React.useEffect(() => {
        if (!data.input) return;
        invoke();
    }, [data.input]);

    function submitRun (inputData: I) {
        setData((prev) =>
            Builder<Data>(prev)
                .input(inputData)
                .build()
        );
    }

    function reload () {
        if (!data.input) return;
        invoke();
    }

    function resetStatus () {
        if (status === "Running") return;
        setStatus("New");
    }

    return {
        status,
        data,
        submitRun,
        reload,
        resetStatus
    };
}

export interface API2Interface<I, O> {
    status: ApiRunnerStatus;
    input: I;
    output: O;
    err: AppError;
    invoke: (i: I) => void;
    resetStatus: () => void;
}

export function useAPI2<I, O> (
    api: (input: I) => Promise<APIResponse<O>>,
): API2Interface<I, O> {
    const [status, setStatus] = React.useState<ApiRunnerStatus>("New");
    const [input, setInput] = React.useState<I>(null);
    const [output, setOutput] = React.useState<O>(null);
    const [err, setErr] = React.useState<AppError>(null);

    function invoke (i: I) {
        let status: ApiRunnerStatus = "Running";
        setStatus(status);
        setInput(i);
        api(i)
            .then((raw) => {
                const output = APIOutput.fromRaw<O>(raw.data);
                if (output.isErr()) {
                    setErr(output.err);
                    setOutput(null);
                    status = "Error";
                } else {
                    setErr(null);
                    setOutput(output.data);
                    status = "Succeeded";
                }
            })
            .catch((e) => {
                setErr((prev) => {
                    let code = 500;
                    let message = UIConstants.ERROR_MESSAGE;
                    if (!!e.response && !!e.response.data && !!e.response.data.message) {
                        message = e.response.data.message;
                    }
                    if (!!e.response && !!e.response.status && e.response.status > 0) {
                        code = e.response.status;
                    }
                    return AppError.create(message, code as ErrorCode);
                });
                status = "Error";
            })
            .finally(() => {
                setStatus(status);
            });
    }

    function resetStatus () {
        if (status === "Running") return;
        setStatus("New");
    }

    return {
        status,
        input,
        output,
        err,
        invoke,
        resetStatus
    };
}