import { SortingField } from "@amzn/ask-legal-domain";
import * as Polaris from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import * as React from "react";
import { DateTimeFormatter } from "../../components/common/DateTimeFormatter";
import { IdentityLink } from "../../components/common/IdentityLink";

export namespace CommonPolarisFactory {
    export namespace Table {
        export namespace PageSizeSelection {
            export const Option_Ten = Builder<
                Polaris.CollectionPreferencesProps.PageSizeOption
            >()
                .value(10)
                .label("10")
                .build();
            export const Option_Twenty = Builder<
                Polaris.CollectionPreferencesProps.PageSizeOption
            >()
                .value(20)
                .label("20")
                .build();
            export const Option_Fifty = Builder<
                Polaris.CollectionPreferencesProps.PageSizeOption
            >()
                .value(50)
                .label("50")
                .build();

            export function toPageSizeSelection(
                options: Polaris.CollectionPreferencesProps.PageSizeOption[]
            ) {
                return Builder<Polaris.CollectionPreferencesProps.PageSizePreference>()
                    .options(options)
                    .title("Select page size")
                    .build();
            }
        }

        export namespace VisibleContentPreference {
            export function toVisibleContentPreference(columns: Polaris.TableProps.ColumnDefinition<any>[]) {
                return Builder<Polaris.CollectionPreferencesProps.VisibleContentPreference>()
                    .title("Select visible content")
                    .options([{
                        label: "Entity properties",
                        options: columns.map(c =>
                            Builder<Polaris.CollectionPreferencesProps.VisibleContentOption>()
                                .id(c.id)
                                .label(c.header)
                                .editable(true)
                                .build()
                        )
                    }])
                    .build();
            }
        }
        export namespace ColumnDefinition {
            export const Id = Builder<Polaris.TableProps.ColumnDefinition<any>>()
                .id("id")
                .header("Id")
                .cell((item: any) => item.id)
                .build();
            export const Name = Builder<Polaris.TableProps.ColumnDefinition<any>>()
                .id("name")
                .header("Name")
                .sortingField(SortingField.Name)
                .cell((item: any) => item.name)
                .build();
            export const Description = Builder<Polaris.TableProps.ColumnDefinition<any>>()
                .id("description")
                .header("Description")
                .cell((item: any) => item.description)
                .build();
            export const LastModifiedBy = Builder<Polaris.TableProps.ColumnDefinition<any>>()
                .id("lastModifiedBy")
                .header("Last Modified By")
                .cell((item: any) => <IdentityLink id={item.lastModifiedBy.id} name={item.lastModifiedBy.name} />)
                .build();
            export const LastModifiedDate = Builder<Polaris.TableProps.ColumnDefinition<any>>()
                .id("lastModifiedDate")
                .header("Last Modified Date")
                .sortingField(SortingField.LastModifiedDate)
                .cell((item: any) => DateTimeFormatter.formatDate(item.lastModifiedDate))
                .build();
            export const CreatedBy = Builder<Polaris.TableProps.ColumnDefinition<any>>()
                .id("createdBy")
                .header("Created By")
                .cell((item: any) => <IdentityLink id={item.createdBy.id} name={item.createdBy.name} />)
                .build();
            export const CreationDate = Builder<Polaris.TableProps.ColumnDefinition<any>>()
                .id("creationDate")
                .header("Creation Date")
                .sortingField(SortingField.CreationDate)
                .cell((item: any) => DateTimeFormatter.formatDate(item.creationDate))
                .build();
            export const Version = Builder<Polaris.TableProps.ColumnDefinition<any>>()
                .id("version")
                .header("Version")
                .cell((item: any) => item.version)
                .build();
            export const Type = Builder<Polaris.TableProps.ColumnDefinition<any>>()
                .id("resourceType")
                .header("Resource Type")
                .cell((item: any) => item.type)
                .build();
            export const IsDeprecated = Builder<Polaris.TableProps.ColumnDefinition<any>>()
                .id("deprecated")
                .header("Deprecated Status")
                .cell((item: any) => item.deprecated ?
                    <Polaris.StatusIndicator type="stopped">Deprecated</Polaris.StatusIndicator> :
                    <Polaris.StatusIndicator type="success">Active</Polaris.StatusIndicator>)
                .build();

            export function toCommonColumnDefinitions() {
                return [
                    Id,
                    Name,
                    IsDeprecated,
                    LastModifiedBy,
                    LastModifiedDate,
                    CreatedBy,
                    CreationDate,
                    Version,
                    Type
                ];
            }

            export function toCommonColumnDefinitionsWithoutName() {
                return [
                    Id,
                    IsDeprecated,
                    LastModifiedBy,
                    LastModifiedDate,
                    CreatedBy,
                    CreationDate,
                    Version,
                    Type
                ];
            }

            export function toSortedColumnDefinition(additionalColumns: Polaris.TableProps.ColumnDefinition<any>[]) {
                return [
                    Id,
                    Name,
                    ...additionalColumns,
                    IsDeprecated,
                    LastModifiedBy,
                    LastModifiedDate,
                    CreatedBy,
                    CreationDate,
                    Version,
                    Type
                ];
            }
        }
    }
}