import * as React from "react";
import { AppContext } from "../../../setup/context";
import { useAPI2 } from "../../../hooks/api-hook";
import { LegalContactModel } from "../../../model/legal-contact-model";
import { SimpleModal } from "../../common/SimpleModal";
import { EditLegalContactForm } from "../EditLegalContactForm";
import { LegalContact } from "@amzn/ask-legal-domain";
import { SpaceBetween } from "@amzn/awsui-components-react";
import { ErrorFlashbar } from "../../common/ErrorFlashbar";

export const UpdateLegalContactModal = (props: {
    state: LegalContactModel.UpdateState;
    onDismiss: () => void;
    onUpdate: (updateOutput: LegalContact.Record) => void;
}) => {
    const context = React.useContext(AppContext);
    const updateLegalContactRunner = useAPI2(
        context.getLegalContactAPI().update
    );

    const onSave = () => {
        updateLegalContactRunner.invoke(
            LegalContactModel.UpdateState.toUpdateInput(props.state)
        );
    };

    React.useEffect(() => {
        if (updateLegalContactRunner.status === "Succeeded") {
            props.onUpdate(updateLegalContactRunner.output);
            props.onDismiss();
        }
    }, [updateLegalContactRunner.status]);

    return (
        <SimpleModal
            child={
                <SpaceBetween size="s">
                    {updateLegalContactRunner.status === "Error" &&
                        <ErrorFlashbar error={updateLegalContactRunner.err} />
                    }
                    <EditLegalContactForm state={props.state} />
                </SpaceBetween>
            }
            header={`Update Legal Contact - ${props.state.legalContactUser.value.name}`}
            loading={updateLegalContactRunner.status === "Running"}
            onCancel={props.onDismiss}
            onSave={onSave}
            disabled={!props.state.isValid()}
            size="max"
        />
    );
};