import { LegalContact, WhosMyLawyerContent } from "@amzn/ask-legal-domain";
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
    Box,
    Button,
    ButtonDropdown,
    ButtonDropdownProps,
    CollectionPreferences,
    CollectionPreferencesProps,
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TableProps,
    TextFilter
} from "@amzn/awsui-components-react";
import * as React from "react";
import { LegalContactsPolarisFactory } from "../../factory/polaris/legal-contacts-polaris-factory";
import { legalContactAriaLabels, legalContactAriaLiveLabels } from "../../i18n/legalContactTableI18nStrings";

export const LegalContactTable = (props: {
    records: LegalContact.Record[];
    loading: boolean;
    onHeaderAction?: (action: string, item?: LegalContact.Record) => void;
    reloadData?: () => void;
    defaultPreference?: CollectionPreferencesProps.Preferences<LegalContact.Record>;
    defaultSortState?: TableProps.SortingState<LegalContact.Record>;
    isEditor?: boolean;
    isLegalContact?: string;
    headerContent?: JSX.Element | string;
    headerDescription?: JSX.Element | string;
    whosMyLawyerContent?: WhosMyLawyerContent;
}) => {
    const [preferences, setPreferences] = React.useState<CollectionPreferencesProps.Preferences<LegalContact.Record>>(
        props.defaultPreference || LegalContactsPolarisFactory.DEFAULT_PREFERENCES
    );

    const collection = useCollection(
        props.records,
        {
            filtering: {
                filteringFunction: LegalContactsPolarisFactory.Filters.filteringFunction,
                fields: LegalContactsPolarisFactory.Filters.defaultFilterFields
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {
                defaultState: props.defaultSortState || LegalContactsPolarisFactory.DEFAULT_SORTING_STATE
            },
            selection: {},
        }
    );

    // Table react components
    const buttonDropdownItems: ButtonDropdownProps.Item[] = [
        {
            id: "update",
            text: "Update Legal Contact",
            disabled: collection.collectionProps.selectedItems.length < 1
        },
        {
            id: "delete",
            text: "Delete Legal Contact",
            disabled: !props.isEditor || collection.collectionProps.selectedItems.length < 1
        }
    ];
    const headerActions = <SpaceBetween size="s" direction="horizontal">
        {props.reloadData && <Button
            variant="icon"
            iconName="refresh"
            loading={props.loading}
            onClick={props.reloadData}
        />}
        {(props.isEditor || props.isLegalContact) && <ButtonDropdown
            items={buttonDropdownItems}
            onItemClick={(e) => props.onHeaderAction(
                e.detail.id,
                collection.collectionProps.selectedItems?.[0]
            )}
            loading={props.loading}
            disabled={collection.collectionProps.selectedItems.length < 1}
        >
            Actions
        </ButtonDropdown>}
        {props.isEditor && <Button
            iconName="add-plus"
            variant="primary"
            onClick={() => props.onHeaderAction("create")}
            loading={props.loading}
        >
            Add Legal Contact
        </Button>}
    </SpaceBetween>;

    const header = <Header
        actions={headerActions}
        counter={`(${collection.items.length}/${props.records.length})${props.loading ? " Retrieving..." : ""}`}
        description={props.headerDescription}
    >
        {props.headerContent}
    </Header>;

    const empty = <Box textAlign="center" color="text-body-secondary" padding={{ top: "xxl" }}>
        <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No legal contacts found.
        </Box>
    </Box>;

    const tablePreferences = <CollectionPreferences
        preferences={preferences}
        onConfirm={({ detail }) => setPreferences(detail)}
        pageSizePreference={{
            options: LegalContactsPolarisFactory.DEFAULT_PAGE_SIZE_OPTIONS,
            title: "Number of contacts per page"
        }}
        stripedRowsPreference={{
            label: "Striped rows",
            description: "Select to add alternating shaded rows"
        }}
        contentDisplayPreference={{
            options: LegalContactsPolarisFactory.Table.getColumnDisplayOptions(),
            title: "Column preferences",
            description: "Customize the columns visibility and order"
        }}
        cancelLabel="Clear"
        confirmLabel="Apply"
        title="Preferences"
        disabled={props.loading}
    />;

    const textFilter = <TextFilter
        {...collection.filterProps}
        filteringAriaLabel="Search contacts"
        filteringPlaceholder="Search contacts"
        filteringClearAriaLabel="Clear"
        countText={`${collection.filteredItemsCount} contacts`}
    />;

    return (
        <Table
            {...collection.collectionProps}
            columnDefinitions={
                LegalContactsPolarisFactory.Table.toColumnDefinition(
                    props.whosMyLawyerContent
                )
            }
            columnDisplay={preferences.contentDisplay}
            items={collection.items}
            selectionType={(props.isEditor || props.isLegalContact) ? "single" : undefined}
            ariaLabels={legalContactAriaLabels}
            renderAriaLive={legalContactAriaLiveLabels}
            variant="container"
            stickyHeader={false}
            resizableColumns={false}
            wrapLines={preferences.wrapLines}
            stripedRows={preferences.stripedRows}
            contentDensity={preferences.contentDensity || "compact"}
            stickyColumns={preferences.stickyColumns || { first: 1, last: 0 }}
            header={props.headerContent && header}
            empty={empty}
            loadingText="Loading contacts"
            loading={props.loading && collection.items.length === 0}
            filter={textFilter}
            pagination={<Pagination {...collection.paginationProps} />}
            preferences={tablePreferences}
            isItemDisabled={item => (!props.isEditor && props.isLegalContact) && item.legalContactUser.id !== props.isLegalContact}
        />
    );
};