import {
    Button,
    ColumnLayout,
    Grid,
    Header,
    MultiselectProps,
    SpaceBetween
} from "@amzn/awsui-components-react";
import * as React from "react";
import { UIField } from "../../common/UIField";
import { StaticListSelect } from "../../common/StaticListSelect";
import { LegalContactTable } from "../LegalContactTable";
import { LegalContact, WhosMyLawyerContent } from "@amzn/ask-legal-domain";
import { WhosMyLawyerFactory } from "../../../factory/whos-my-lawyer-factory";

export const ViewByLegalTeams = (props: {
    items: LegalContact.Record[];
    content: WhosMyLawyerContent;
    loading?: boolean;
}) => {
    const [selectedTeams, setSelectedTeams] = React.useState<string[]>([]);
    const [selectedSubTeams, setSelectedSubTeams] = React.useState<string[]>([]);

    const resolveTeamOptions = () => {
        return [...new Set(props.items.map(x => x.legalTeamOptions?.[0]?.displayValue))]
            .filter(t => t?.trim().length);
    };
    const resolveSubTeamOptions = () => {
        return [...new Set(props.items.map(x => x.legalSubTeamOptions).flat().map(t => t?.displayValue))]
            .filter(t => t?.trim().length);
    };
    const resolveTeamFilter = (records: LegalContact.Record[]) => {
        return records.filter(x => {
            const team = x.legalTeamOptions?.[0];
            return team && selectedTeams.includes(team.displayValue);
        });
    };
    const resolveSubTeamFilter = (records: LegalContact.Record[]) => {
        return records.filter(x => {
            const subTeams = x.legalSubTeamOptions;
            return subTeams && subTeams.some(y => selectedSubTeams.includes(y.displayValue));
        });
    };

    const filterRecords = (records: LegalContact.Record[]): LegalContact.Record[] => {
        if (selectedTeams.length === 0 && selectedSubTeams.length === 0) return records;
        if (selectedTeams.length === 0) return resolveSubTeamFilter(records);
        if (selectedSubTeams.length === 0) return resolveTeamFilter(records);
        return records.filter(x => resolveTeamFilter(records) && resolveSubTeamFilter(records));
    };

    return <SpaceBetween size="xxl">
        <Grid gridDefinition={[
            { colspan: 3 },
            { colspan: 9 }
        ]}>
            <ColumnLayout columns={1}>
                <Header>Filter By Legal Team</Header>
                <UIField.CustomField
                    name="Legal Team"
                    child={
                        <StaticListSelect.Multi<string>
                            options={resolveTeamOptions()}
                            fromOption={(option: MultiselectProps.Option) => option.value}
                            toOption={(team: string) => ({ label: team, value: team })}
                            onSelectChange={(options: string[]) => setSelectedTeams(options)}
                            selected={selectedTeams}
                            disabled={props.loading}
                            sortingFunction={(o) => o.sort((a, b) => a.localeCompare(b))}
                        />
                    }
                />
                <UIField.CustomField
                    name="Legal Sub-Team"
                    child={
                        <StaticListSelect.Multi<string>
                            options={resolveSubTeamOptions()}
                            fromOption={(option: MultiselectProps.Option) => option.value}
                            toOption={(team: string) => ({ label: team, value: team })}
                            onSelectChange={(options: string[]) => setSelectedSubTeams(options)}
                            selected={selectedSubTeams}
                            disabled={props.loading}
                            sortingFunction={(o) => o.sort((a, b) => a.localeCompare(b))}
                        />
                    }
                />
                {(selectedTeams.length > 0 || selectedSubTeams.length > 0) && <Button variant="normal" onClick={() => {
                    setSelectedTeams([]);
                    setSelectedSubTeams([]);
                }}>Reset Filters</Button>}
            </ColumnLayout>
            <LegalContactTable
                records={filterRecords(props.items)}
                loading={props.loading}
                defaultSortState={WhosMyLawyerFactory.getSortingStateFromContent(props.content)}
                defaultPreference={WhosMyLawyerFactory.toPreferencesFromDisplayConfiguration(
                    props.content.defaultConfiguration
                )}
                whosMyLawyerContent={props.content}
            />
        </Grid>
    </SpaceBetween>;
};