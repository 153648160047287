import * as React from "react";
import { AppContext } from "../../setup/context";
import {
    Box,
    Button,
    ButtonDropdown,
    CollectionPreferences,
    Flashbar,
    Header,
    Modal,
    Pagination,
    PropertyFilter,
    PropertyFilterProps,
    SpaceBetween,
    Table
} from "@amzn/awsui-components-react";
import { CommonPolarisFactory } from "../../factory/polaris/common-polaris-factory";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { LabelModalContent } from "./modal-content/LabelModalContent";
import { useAPI } from "../../hooks/api-hook";
import { LabelModel } from "../../model/label-model";
import { SimpleModal } from "../common/SimpleModal";
import { useLoadingWithServerSidePagination } from "../../hooks/polaris-collection-hook";
import { LabelPolarisFactory } from "../../factory/polaris/label-polaris-factory";
import { FilterField, Label } from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import { StringUtils } from "../common/common-utils";

export const statusFilter = Builder<PropertyFilterProps.FilteringProperty>()
    .key(FilterField.Deprecated)
    .operators(["="])
    .propertyLabel("Is Deprecated?")
    .groupValuesLabel("Available statuses")
    .build();

export const nameFilter = Builder<PropertyFilterProps.FilteringProperty>()
    .key(FilterField.Name)
    .operators(["="])
    .propertyLabel("Title")
    .build();

export const LabelTable = (props: {
    id: string
}) => {
    const context = React.useContext(AppContext);
    const [modalAction, setModalAction] = React.useState<string>("");
    const [statusUpdates, setStatusUpdates] = React.useState<Array<any>>([]);

    const createLabelRunner = useAPI(context.getLabelAPI().create);
    const updateLabelRunner = useAPI(context.getLabelAPI().update);

    const labelState = LabelModel.LabelState.use({ instanceId: props.id });

    const createLabelButton = (
        <Button
            variant="primary"
            onClick={() => actionHandler("create")}
        >
            Create Label
        </Button>
    );

    const collection = useLoadingWithServerSidePagination(
        context.getLabelAPI().loadByInstance,
        props.id,
        {
            columnDefinitions: LabelPolarisFactory.Table.toColumnDefinitions(),
            defaultTablePreferences: LabelPolarisFactory.Table.toDefaultTablePreferences(),
            pageSizeSelectionOptions: [
                CommonPolarisFactory.Table.PageSizeSelection.Option_Ten,
                CommonPolarisFactory.Table.PageSizeSelection.Option_Twenty,
                CommonPolarisFactory.Table.PageSizeSelection.Option_Fifty
            ],
            selectionType: "single"
        },
        {
            filteringProperties: [nameFilter, statusFilter],
            toOption: () => [
                { propertyKey: FilterField.Deprecated, value: "Yes" },
                { propertyKey: FilterField.Deprecated, value: "No" }
            ]
        }
    );

    const counter = () => {
        if (collection.table.props.loading) return "";
        return `(${collection.table.props.selectedItems.length}/${collection.table.props.items.length})`;
    };
    const header = (
        <Header variant="h2" counter={counter()} actions={
            <SpaceBetween direction="horizontal" size="m">
                <Button variant="icon" iconName="refresh" onClick={() => {
                    if (collection) {
                        collection.loadItems();
                    }
                }}/>
                <ButtonDropdown
                    loading={false}
                    disabled={collection.table.props.items.length < 1}
                    items={[{
                        text: "Edit",
                        id: "update",
                        disabled: collection.table.props.selectedItems.length !== 1
                    }, {
                        text: "Deprecate",
                        id: "deprecate",
                        disabled: collection.table.props.selectedItems.length !== 1 || (collection.table.props.selectedItems[0] as Label.Data)?.deprecated
                    }, {
                        text: "Activate",
                        id: "activate",
                        disabled: collection.table.props.selectedItems.length !== 1 || !(collection.table.props.selectedItems[0] as Label.Data)?.deprecated
                    }]}
                    onItemClick={(event: { detail: { id: string } }) => {
                        actionHandler(event.detail.id);
                    }}>Actions</ButtonDropdown>
                {createLabelButton}
            </SpaceBetween>
        }>Labels</Header>
    );
    const empty = (
    <Box textAlign="center" color="inherit">
        <b>
            No Labels found
        </b>
        <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No Labels found in this Instance
        </Box>
        {createLabelButton}
    </Box>);

    const dismiss = () => {
        labelState.reset();
        setModalAction("");
    };

    const deprecationHandler = () => {
        if (modalAction !== "deprecate" && modalAction !== "activate") return;
        let updateInput = LabelModel.LabelState.toUpdateInput(labelState, collection.table.props.selectedItems[0]);
        updateInput.isDeprecated = modalAction === "deprecate";
        updateLabelRunner.submitRun(updateInput);
    };

    const actionHandler = (action: string) => {
        switch (action) {
            case "create":
                labelState.init();
                setModalAction("create");
                break;
            case "update":
                labelState.init(collection.table.props.selectedItems[0]);
                setModalAction("update");
                break;
            case "deprecate":
                labelState.init(collection.table.props.selectedItems[0]);
                setModalAction("deprecate");
                break;
            case "activate":
                labelState.init(collection.table.props.selectedItems[0]);
                setModalAction("activate");
                break;
            default: break;
        }
    };

    React.useEffect(() => {
        if (createLabelRunner.status === "Succeeded") {
            dismiss();
            collection.loadItems();
            setStatusUpdates([{
                type: "success",
                content: `Label: ${createLabelRunner.data.output.name} was successfully created.`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        } else if (createLabelRunner.status === "Error") {
            dismiss();
            setStatusUpdates([{
                type: "error",
                content: `Failed to create label. Reason: ${createLabelRunner.data.err.message}`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        }
    }, [createLabelRunner.status]);

    React.useEffect(() => {
        if (updateLabelRunner.status === "Succeeded") {
            dismiss();
            collection.loadItems();
            setStatusUpdates([{
                type: "success",
                content: `Label: ${updateLabelRunner.data.output.name} was successfully updated.`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        } else if (updateLabelRunner.status === "Error") {
            dismiss();
            setStatusUpdates([{
                type: "error",
                content: `Failed to update label. Reason: ${updateLabelRunner.data.err.message}`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        }
    }, [updateLabelRunner.status]);

    return (
        <React.Fragment>
            <SpaceBetween size="s">
                <Flashbar items={statusUpdates}/>
                { !!collection.table.error && <ErrorFlashbar error={collection.table.error}/>}
                {modalAction === "create" &&
                    <SimpleModal
                        child={<LabelModalContent state={labelState}/>}
                        header={"Create New Label"}
                        loading={createLabelRunner.status === "Running"}
                        onCancel={dismiss}
                        onSave={() => {
                            createLabelRunner.submitRun(LabelModel.LabelState.toCreateInput(labelState));
                        }}
                        disabled={
                            !!labelState.name.errorText
                        }
                    />
                }
                {modalAction === "update" &&
                    <SimpleModal
                        child={<LabelModalContent state={labelState}/>}
                        header={"Update Label"}
                        loading={updateLabelRunner.status === "Running"}
                        onCancel={dismiss}
                        onSave={() => {
                            updateLabelRunner.submitRun(LabelModel.LabelState.toUpdateInput(labelState, collection.table.props.selectedItems[0]));
                        }}
                        disabled={
                            !!labelState.name.errorText
                        }
                    />
                }
                {(modalAction === "deprecate" || modalAction === "activate") &&
                    <Modal
                        visible={true}
                        size="medium"
                        header={`${StringUtils.capitalizeFirstLetter(modalAction)} Label`}
                        footer={
                            <Box float="right">
                                <Button variant="link" loading={updateLabelRunner.status === "Running"} onClick={dismiss}>
                                    No
                                </Button>
                                <Button variant="primary" loading={updateLabelRunner.status === "Running"} onClick={deprecationHandler}>
                                    Yes
                                </Button>
                            </Box>
                        }
                        onDismiss={dismiss}>
                        Are you sure you want to {modalAction} this label?
                        <br/><b><li>{collection.table.props.selectedItems[0].name}</li></b>
                    </Modal>
                }
                <Table
                    {...collection.table.props}
                    empty={empty}
                    header={header}
                    preferences={
                        <CollectionPreferences {...collection.tablePreference.props}/>
                    }
                    pagination={
                        <Pagination {...collection.tablePagination.props}/>
                    }
                    filter={
                        <PropertyFilter {...collection.tableFiltering.props}/>
                    }
                />
            </SpaceBetween>
        </React.Fragment>
    );
};