import { validUrlRegex } from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import * as React from "react";

export namespace UIModel {
    export namespace FieldValidation {
        export function assertNotNullString (value?: string) {
            if (value === null || value === undefined || value.trim().length === 0) return "Value cannot be empty";
        }

        export function regexValidation (regex: RegExp, value: string) {
            if (value === null || value === undefined || value.length === 0) return "Value cannot be empty";
            if (!value.match(regex)) {
                if (regex === validUrlRegex) return `Value entered is not a valid URL`;
                return `Value entered does not match the specified format`;
            }
        }

        export function regexValidationAllowEmpty (regex: RegExp, value: string) {
            if (value === null || value === undefined || value.length === 0) return;
            if (!value.match(regex)) {
                if (regex === validUrlRegex) return `Value entered is not a valid URL`;
                return `Value entered does not match the specified format`;
            }
        }

        export function assertNotNull<T> (value?: T) {
            if (value === null || value === undefined) return "Value cannot be empty";
        }

        export function assertNotNullAndEmpty<T> (value?: T[]) {
            if (!value) return "Value cannot be null";
            if (value.length === 0) return "Value cannot be empty";
        }

        export function assertNumberRange (low?: number, high?: number) {
            return (value: number, low?: number, high?: number) => {
                if (!value) return "Value cannot be null";
                if (low && value < low) return `Value cannot be less than ${ low }`;
                if (high && value > high) return `Value cannot be greater than ${ high }`;
            };
        }

        export function isValidURL(str: string) {
            return !!validUrlRegex.test(str);
        }

        export function isValidMailto(str: string) {
            const regexStr = /^mailto:(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
            const reg = new RegExp(regexStr);
            return reg.test(str);
        }

        export function assertWithinCharLimit (limit: number, value?: string, allowEmpty?: boolean) {
            if (!allowEmpty && (value === null || value === undefined || value.length === 0)) return "Value cannot be empty";
            if (value.length > limit) return `Exceeded character limit of ${limit}`;
        }

        export function assertArrLength<T>(limit: number, value?: Array<T>, message?: string) {
            if (!!value && value.length > limit) return !message ? "Exceeded size limit of " + limit : message;
        }

        export function assertValidRegexAndCharLimit (regex: RegExp, limit: number, value?: string) {
            if (!value || value.length === 0) return "Value cannot be empty";
            if (!value.match(regex)) {
                if (regex === validUrlRegex) return `Value entered is not a valid URL`;
                return `Value entered does not match the specified format`;
            }
            if (value.length > limit) return `Exceeded character limit of ${limit}`;
        }
    }
    export class State<T> {
        value: T;
        setValue: (value: T) => void;
        errorText: string;
        reset: () => void;

        private static create<T> (props: {
            value?: T;
            initialValue?: T;
            errorText?: string;
            setValue: (t: T) => void;
            reset?: () => void;
        }): State<T> {
            const builder = Builder(new State<T>())
                .value(props.initialValue)
                .errorText(props.errorText)
                .reset(props.reset)
                .setValue(props.setValue);

            return builder.build();
        }

        static use<T> (props: {
            initialValue?: T;
            validation?: (t: T) => string;
        }): State<T> {
            const [ value, _setValue ] = React.useState<T>(props.initialValue);
            const [ errorText, setErrorText ] = React.useState<string>(() =>
                props.validation ? props.validation(props.initialValue) : null
            );

            const setValue = (v: T) => {
                _setValue(v);
                if (props.validation) {
                    setErrorText(props.validation(v));
                }
            };

            return State.create({
                initialValue: value,
                errorText: errorText,
                setValue: setValue,
                reset: () => setValue(props.initialValue ? props.initialValue : null)
            });
        }

        static useNotNullString (props: {
            initialValue?: string
        }): State<string> {
            return State.use({
                initialValue: props.initialValue,
                validation: FieldValidation.assertNotNullString
            });
        }

        static useArray<T> (props: {
            initialValue?: T[]
            validation?: (t: T) => string
        }): State<T[]> {
            const [ value, setValue ] = React.useState<T[]>(props.initialValue ? props.initialValue : []);

            return State.create({
                initialValue: value,
                setValue: (t: T[]) => setValue(t),
                reset: () => setValue(props.initialValue ? props.initialValue : [])
            });
        }

        static useArrayWithConstraint<T> (props: {
            initialValue?: T[]
            validation?: (t: T[]) => string
        }): State<T[]> {
            const [ value, _setValue ] = React.useState<T[]>(props.initialValue ? props.initialValue : []);
            const [ errorText, setErrorText ] = React.useState<string>(() =>
                props.validation ? props.validation(props.initialValue) : null
            );

            const setValue = (t: T[]) => {
                _setValue(t);
                if (props.validation) {
                    setErrorText(props.validation(t));
                }
            };

            return State.create({
                initialValue: value,
                errorText: errorText,
                setValue: (t: T[]) => setValue(t),
                reset: () => setValue(props.initialValue ? props.initialValue : [])
            });
        }

        static useArrayWithLimit<T> (props: {
            initialValue?: T[]
            errorMessage?: string
            limit: number
        }): State<T[]> {
            return State.useArrayWithConstraint({
                initialValue: props.initialValue,
                validation: (t: T[]) => FieldValidation.assertArrLength(props.limit, t, props.errorMessage)
            });
        }

        static useWithRegexValidation (props: {
            initialValue?: string
            regex: RegExp
        }): State<string> {
            return State.use({
                initialValue: props.initialValue,
                validation: (t: string) => FieldValidation.regexValidation(props.regex, t)
            });
        }

        static useRequired<T> (props: {
            initialValue?: T;
        }): State<T> {
            return State.use({
                initialValue: props.initialValue,
                validation: FieldValidation.assertNotNull
            });
        }

        static useRequiredArray<T> (props: {
            initialValue?: T[],
        }): State<T[]> {
            return State.use({
                initialValue: props.initialValue,
                validation: FieldValidation.assertNotNullAndEmpty
            });
        }

        static useNotNullStringWithCharLimit (props: {
            initialValue?: string
            characterLimit: number
        }): State<string> {
            return State.use({
                initialValue: props.initialValue,
                validation: (t: string) => FieldValidation.assertWithinCharLimit(props.characterLimit, t)
            });
        }

        static useWithRegexAndCharLimit (props: {
            initialValue?: string
            regex: RegExp
            characterLimit: number
        }): State<string> {
            return State.use({
                initialValue: props.initialValue,
                validation: (t: string) => FieldValidation.assertValidRegexAndCharLimit(props.regex, props.characterLimit, t)
            });
        }

        static useOptionalWithRegexValidation (props: {
            initialValue?: string
            regex: RegExp
        }): State<string> {
            return State.use({
                initialValue: props.initialValue,
                validation: (t: string) => FieldValidation.regexValidationAllowEmpty(props.regex, t)
            });
        }
    }
}
