import {
    Multiselect,
    MultiselectProps,
    Select,
    SelectProps
} from "@amzn/awsui-components-react";
import * as React from "react";
import { useComponnentProps } from "../../hooks/polaris-component-hooks";

export namespace StaticListSelect {
    export const Single = <T extends any>(props: {
        data: T[];
        selected: T;
        onSelectChange: (t: T) => void;
        fromOption: (option: SelectProps.Option) => T;
        toOption: (t: T) => SelectProps.Option;
        disabled?: boolean;
    }) => {
        const selectProps = useComponnentProps<SelectProps>({
            placeholder: "Select from dropdown",
            options: props.data.map(d => props.toOption(d)),
            filteringPlaceholder: "Type to filter",
            selectedOption: props.toOption(props.selected),
            onChange: e => props.onSelectChange(props.fromOption(e.detail.selectedOption)),
            disabled: props.disabled,
            filteringType: "auto"
        });

        React.useEffect(() => {
            selectProps.setProps(prev => ({
                ...prev,
                selectedOption: props.toOption(props.selected)
            }));
        }, [props.selected]);

        return <Select {...selectProps.props}/>;
    };

    export const Multi = <T extends any>(props: {
        options: T[];
        selected: T[];
        onSelectChange: (options: T[]) => void;
        fromOption: (option: MultiselectProps.Option) => T;
        toOption: (t: T) => MultiselectProps.Option;
        disabled?: boolean;
        sortingFunction?: (options: T[]) => T[];
    }) => {
        const applySortingFunction = (options: T[]): T[] => {
            if (!props.sortingFunction) return options;
            return props.sortingFunction(options);
        };

        const componentProps = useComponnentProps<MultiselectProps>({
            selectedOptions: props.selected.map(d => props.toOption(d)),
            options: applySortingFunction(props.options).map(d => props.toOption(d)),
            onChange: e => props.onSelectChange(e.detail.selectedOptions.map(o => props.fromOption(o))),
            placeholder: "Select from dropdown",
            disabled: props.disabled,
            filteringType: "auto",
            tokenLimit: 2
        });

        React.useEffect(() => {
            componentProps.setProps(prev => ({
                ...prev,
                selectedOptions: props.selected.map(d => props.toOption(d))
            }));
        }, [props.selected]);

        return <Multiselect {...componentProps.props} />;
    };
}