import {
  AdvancedList,
  AdvancedListSearchItem,
  LoadAdvancedListCommandOutput,
  SearchAdvancedListItemsCommandOutput,
  FieldDefinitionDataType
} from "@amzn/altar-sds-client";
import { APIOutput, AdvancedListContent, ContainerRef, Identity } from "@amzn/ask-legal-domain";
import { Button, Flashbar, Header, Input, SpaceBetween, Spinner, SpinnerProps, Table, TableProps } from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../../setup/context";
import { CreateAdvancedListItemModal } from "../../advanced-list/CreateAdvancedListItemModal";
import { FlashbarProps } from "@amzn/awsui-components-react-v3";

export const AdvancedListContentView = (props: {
  content: AdvancedListContent;
  containerRef: ContainerRef;
  showEditControls?: boolean;
}) => {
  const context = React.useContext(AppContext);
  const [userIdentity, setUserIdentity] = React.useState<Identity>(null);
  const [spinnerProps, setSpinnerProps] = React.useState<SpinnerProps>();
  const [flashbarProps, setFlashbarProps] = React.useState<Pick<FlashbarProps, "items">>();
  const [tableProps, setTableProps] = React.useState<
    Pick<TableProps, "items" | "loading" | "loadingText" | "empty" | "columnDefinitions"> & {
      advancedList?: AdvancedList;
    }
  >();
  const [createItemModalProps, setCreateItemModalProps] =
    React.useState<Parameters<typeof CreateAdvancedListItemModal>[0]>();

  const isLive = window.location.href.includes("/live");

  async function init() {
    const userIdentity = await context.getIdentity();
    if (!userIdentity) {
      setFlashbarProps({
        items: [{
          type: "error",
          content: "No current user information. Try to refresh the page again."
        }]
      });
      return;
    }

    if (!props.content?.entityRef) {
      // New blank container draft
      setFlashbarProps(undefined);
      return;
    }

    try {
      setSpinnerProps({});
      const loadOutput = await context.getAdvancedListAPI().load({
        entityId: props.content?.entityRef.entityId,
        repositoryId: props.content?.entityRef.repositoryRef.repositoryId,
        by: {
          id: userIdentity.id,
          realm: "Amazon",
          type: "Person"
        }
      });

      const columnDefinitions: TableProps.ColumnDefinition<AdvancedListSearchItem>[] = [];
      let advancedList: AdvancedList;
      let emptyText: string;

      const output = APIOutput.fromRaw<LoadAdvancedListCommandOutput>(loadOutput.data);
      if (output.isOk()) {
        // Have existing AL
        advancedList = output.data.body;
        columnDefinitions.push(
          ...advancedList.fieldDefinitions.map<TableProps.ColumnDefinition<AdvancedListSearchItem>>((f) => ({
            header: f.displayName,
            id: f.displayName,
            cell: (item: AdvancedListSearchItem) => item.values.find((e) => e.key === f.fieldKey)?.value,
            editConfig: {
              ariaLabel: f.displayName,
              editIconAriaLabel: "editable",
              errorIconAriaLabel: `${f.displayName} Error`,
              editingCell: (
                item,
                { currentValue, setValue }
              ) => {
                const itemField = item.values.find((e) => e.key === f.fieldKey);
                if (f.dataType === FieldDefinitionDataType.string) {
                  return (
                    <Input
                      autoFocus={true}
                      value={currentValue ?? itemField?.value}
                      onChange={(event) => setValue(event.detail.value)}
                    />
                  );
                }
                alert("Not supported");
              },
              disabledReason: item => {
                return undefined;
              }
            }
          }))
        );
      } else if (output.err.code === 400 && isLive) {
        // No existing AL and is on live page
        emptyText = "List is under creation. Try again after a few seconds.";
      }

      if (!isLive) {
        // Draft mode. Add all pending columns
        columnDefinitions.push(
          ...(props.content?.updateAdvancedListPayload?.addFieldDefinitionsList || []).map<
            TableProps.ColumnDefinition<AdvancedListSearchItem>
          >((f) => ({
            header: `${f.displayName} (pending)`,
            id: f.displayName,
            cell: (item) => <></>
          }))
        );
      }

      setTableProps((prev) => ({
        ...prev,
        advancedList: advancedList,
        columnDefinitions: columnDefinitions,
        loading: false,
        empty: emptyText
      }));
      setFlashbarProps(undefined);
    } catch (err) {
      setFlashbarProps({
        items: [
          {
            type: "error",
            content: (err as Error).message
          }
        ]
      });
    } finally {
      setSpinnerProps(undefined);
    }
  }

  async function searchItems() {
    try {
      const userIdentity = await context.getIdentity();

      setTableProps((prev) => ({
        ...prev,
        loading: true,
        loadingText: "loading items"
      }));

      const searchItemsOutput = await context.getAdvancedListAPI().searchItems({
        listEntityId: props.content.entityRef.entityId,
        listRepositoryId: props.content.entityRef.repositoryRef.repositoryId,
        by: {
          id: userIdentity.id,
          realm: "Amazon",
          type: "Person"
        }
      });

      const output = APIOutput.fromRaw<SearchAdvancedListItemsCommandOutput>(searchItemsOutput.data);
      if (output.isErr()) {
        setTableProps((prev) => ({
          ...prev,
          loading: false,
          loadingText: output.err.message
        }));
      } else {
        setTableProps((prev) => ({
          ...prev,
          loading: false,
          loadingText: undefined,
          items: output.data.items
        }));
      }
    } catch (err) {
      setTableProps((prev) => ({
        ...prev,
        loading: false,
        loadingText: (err as Error).message
      }));
    } finally {
      setTableProps((prev) => ({
        ...prev,
        loading: false
      }));
    }
  }

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    if (tableProps?.advancedList) {
      searchItems();
    }
  }, [tableProps?.advancedList]);

  return (
    <>
      {spinnerProps && <Spinner />}
      {flashbarProps && <Flashbar {...flashbarProps}/>}
      {createItemModalProps && (
        <CreateAdvancedListItemModal
          {...createItemModalProps}
          onCreated={(item) => {
            setTableProps((prev) => ({
              ...prev,
              items: [item, ...prev.items]
            }));
            setCreateItemModalProps(undefined);
          }}
          onCanceled={() => {
            setCreateItemModalProps(undefined);
          }}
        />
      )}
      {tableProps && (
        <Table
          {...tableProps}
          header={
            <Header
              info={props.content?.updateAdvancedListPayload?.description || tableProps.advancedList?.description}
              actions={
                <SpaceBetween size="s">
                  <Button
                    disabled={!tableProps.advancedList}
                    onClick={(e) => {
                      setCreateItemModalProps({
                        listRef: props.content?.entityRef,
                        advancedList: tableProps.advancedList,
                        by: {
                          id: userIdentity.id,
                          realm: "Amazon",
                          type: "Person"
                        }
                      });
                    }}
                  >
                    New Item
                  </Button>
                </SpaceBetween>
              }
            >
              {props.content?.updateAdvancedListPayload?.name || tableProps.advancedList?.name}
            </Header>
          }
        />
      )}
    </>
  );
};
