import { IUserProfile, PageLibraryFactory } from "@amzn/ask-legal-domain";
import { Grid, Icon, Spinner } from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import { useHistory } from "react-router-dom";
import "../../styles/util-styles.scss";
import "../../styles/home-page/instance-home-widgets.scss";
import "../../styles/home-page/responsive.scss";
import { LegalOnlyBadge } from "../common/LegalOnlyTag";

export const RecentViewsWidget = (props: { instanceId: string }) => {
    const context = React.useContext(AppContext);
    const [pageSize, setPageSize] = React.useState<number>(4);
    const [currentPage, setCurrentPage] = React.useState<number>(0);
    const [loadOutput, setLoadOutput] = React.useState<IUserProfile.LoadRecentViewsOutput>();
    const [expanded, setExpanded] = React.useState<boolean>(true);

    const loadRecentViewsRunner = useAPI(context.getUserProfileAPI().loadRecentViews);

    React.useEffect(() => {
        // listen to window size to change pageSize accordingly to best fit the page
        // Not yet implemented
    });

    const fetchRecentViews = () => {
        loadRecentViewsRunner.submitRun(IUserProfile.LoadRecentViewsInput.create(
            props.instanceId,
            {
                pageSize: pageSize,
                currentPage: currentPage
            }
        ));
    };

    React.useEffect(() => {
        fetchRecentViews();
    }, [props.instanceId, pageSize, currentPage]);

    React.useEffect(() => {
        if (loadRecentViewsRunner.status === "Succeeded") {
            setLoadOutput(loadRecentViewsRunner.data.output);
        }
    }, [loadRecentViewsRunner.status]);


    const renderEmptyRecentViews = () => {
        return (
            <div className="vertical-center other-widget-empty">
                You haven't visited any pages yet.  As you navigate the site, the pages you visit will be automatically populated here so you can easily return to a page you've previously visited.
            </div>
        );
    };

    const recentViewCards = () => {
        const cardProps: RecentViewsCardProps[] = [];
        for (let index = 0; index < pageSize; index++) {
            if (
                loadRecentViewsRunner.status === "Succeeded" &&
                !!loadOutput &&
                index < loadOutput.recentViews.length
            ) {
                cardProps.push({
                    description: loadOutput.recentViews[index].description,
                    pageTitle: loadOutput.recentViews[index].pageTitle,
                    pageId: loadOutput.recentViews[index].pageId,
                    loading: false,
                });
            } else if (loadRecentViewsRunner.status === "Succeeded" && !!loadOutput) {
                cardProps.push({
                    description: "No more recent views",
                    pageTitle: "No more recent views",
                    pageId: "",
                    loading: true,
                    isEmpty: true
                });
            } else {
                cardProps.push({
                    description: "Loading...",
                    pageTitle: "Loading...",
                    pageId: "",
                    loading: true,
                });
            }
        }
        return (
            <React.Fragment>
                <Grid className="homepage-responsive" gridDefinition={[
                        { colspan: { default: 12, s: 3 } },
                        { colspan: { default: 12, s: 3 } },
                        { colspan: { default: 12, s: 3 } },
                        { colspan: { default: 12, s: 3 } },
                    ]}>
                    {cardProps.map(cardProp => <RecentViewCard {...cardProp}/>)}
                </Grid>
            </React.Fragment>
        );
    };

    return (<React.Fragment>
        <div className="home-page-sub-header responsive-header">
        <div onClick={() => setExpanded(!expanded)} className="responsive">
                {expanded ? <Icon name="caret-down-filled" size="big"/> : <Icon name="caret-right-filled" size="big"/>}
            </div>
            <div className="title">
                    Recently Visited
            </div>
        </div>
        {expanded && <div className="card-deck other-widget-main">
            {loadRecentViewsRunner.status === "Running" && <div className="vertical-center other-widget-empty"><Spinner variant="inverted" size="large"/></div>}
            {loadRecentViewsRunner.status === "Succeeded" && loadRecentViewsRunner.data.output.totalCount <= 0 && renderEmptyRecentViews()}
            {loadRecentViewsRunner.status === "Succeeded" && loadRecentViewsRunner.data.output.totalCount > 0 &&
                <React.Fragment>
                    <div
                        className={"vertical-center " + (currentPage > 0 ? "other-widget-navigate-icon" : "other-widget-navigate-icon-disabled")}
                        onClick={() => {
                            if (currentPage > 0) {
                                setCurrentPage(currentPage - 1);
                            }}}>
                        <Icon
                            name="angle-left-double"
                            size="big"
                        />
                    </div>
                    {recentViewCards()}
                    <div
                        className={"vertical-center " + ((!!loadOutput && (currentPage + 1) * pageSize < loadOutput.totalCount) ? "other-widget-navigate-icon" : "other-widget-navigate-icon-disabled")}
                        onClick={() => {
                            if (!!loadOutput && (currentPage + 1) * pageSize < loadOutput.totalCount) {
                                setCurrentPage(currentPage + 1);
                            }}}>
                        <Icon
                            name="angle-right-double"
                            size="big"
                        />
                    </div>
                </React.Fragment>}
            </div>}
        </React.Fragment>
    );
};

interface RecentViewsCardProps {
    pageId: string;
    description: string;
    pageTitle: string;
    loading: boolean;
    isEmpty?: boolean;
}

const RecentViewCard = (props: RecentViewsCardProps) => {
    const history = useHistory();

    if (!!props.isEmpty) {
        return (<div className="card bg-dark text-white other-widget-card-empty">
            <div className="empty-body"></div></div>);
    }

    if (!!props.loading) {
        return <div className="card bg-dark text-white other-widget-card-empty">
                <div className="empty-body vertical-center">
                    <Spinner />
                </div>
            </div>;
    }

    return (
        <div onClick={() => {
            if (!props.loading) history.push(`/page/${props.pageId}/live`);
        }} className="card bg-dark text-white other-widget-card">
            <div className="card-body other-widget-card-body">
                <h5 className="card-title text-center">
                    {props.pageTitle}
                    <LegalOnlyBadge pageLibraryId={PageLibraryFactory.fromEntityId(props.pageId)}/>
                </h5>
                <p className="card-text">{props.description}</p>
            </div>
        </div>
    );
};